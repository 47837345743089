export type Predicate = (val: unknown) => boolean;

export type ComponentColor = "primary" | "danger" | "success" | "warning" | "default" | "menu" | "black" | "white";
export const colorValidator = function (
  ...custom: (string | null)[]
): Predicate {
  return (val: unknown): boolean =>
    val === null ||
    (
      typeof val === "string" &&
      ["primary", "danger", "success", "warning", "default", "menu", "black", "white", ...custom].includes(val)
    );
};

export function anyOf<T>(...allowed: T[]): Predicate {
  return (val: unknown): boolean => allowed.some(t => val === t);
}

export function anyTypeOf(...allowed: string[]): Predicate {
  return (val: unknown): boolean => allowed.some(t => typeof val === t);
}

export const MailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
export const PasswordRegex = new RegExp(process.env.VUE_APP_PASSWORD_PATTERN as string);
