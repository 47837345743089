































































































import Vue from "vue";
import Component from "vue-class-component";
import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import SmbCodeInput from "@/components/ui/SmbCodeInput/SmbCodeInput.vue";
import type {ApiMessage} from "@/api/models/common/ApiResponse";
import type {UpdateAccountMailPostForm} from "@/api/models/account/UpdateAccountMailPostForm";
import type {ConfirmUpdateAccountMailPostForm} from "@/api/models/account/ConfirmUpdateAccountMailPostForm";
import {fail, fromResponse} from "@/api/helper";
import ApiClient from "@/api/client/ApiClient";
import {ErrorReporter} from "@/api/client/ErrorReporter";
import type {ComponentColor} from "@/components/ui/helpers";
import { MailRegex} from "@/components/ui/helpers";
import {mapActions, mapState} from "pinia";
import {useBusyStore} from "@/store/busy";
import {useAuthStore} from "@/store/auth";

@Component({
  components: {SmbApiAlert, SmbButton, SmbTextInput, SmbCodeInput },
  computed: {
    ...mapState(useAuthStore, {
      currentMail: state => state.authenticatedUser?.mail,
    }),
  },
  methods: {
    ...mapActions(useBusyStore, ["setViewport", "setGlobal"]),
    ...mapActions(useAuthStore, ["resetUser"]),
  }
})
export default class ChangeMailForm extends Vue {
  public readonly currentMail!: string | undefined;
  public readonly setViewport!: (show: boolean, message?: string, color?: ComponentColor) => void;
  public readonly setGlobal!: (show: boolean, message?: string, color?: ComponentColor) => void;
  public readonly resetUser!: () => void;

  public readonly mailRegex = MailRegex;

  public message: ApiMessage | null = null;
  public changeRequested = false;
  public oldMailConfirmed = false;

  public requestForm: UpdateAccountMailPostForm = {
    mail: "",
  };

  public confirmOldForm: ConfirmUpdateAccountMailPostForm = {
    code: "",
  };

  public confirmNewForm: ConfirmUpdateAccountMailPostForm = {
    code: "",
  };

  public async submitRequest(): Promise<void> {
    try {
      this.setViewport(true, "E-Mail wird gesendet …");

      const response = await ApiClient.postJson("Authentication/UpdateAccountEmail", this.requestForm);
      this.message = fromResponse(response);
      this.changeRequested = response.success;
    } catch (e: unknown) {
      this.message = fail("Fehler beim Senden der Anfrage.");

      ErrorReporter.report(e as Error);
    } finally {
      this.setViewport(false);
    }
  }

  public async confirmOld(): Promise<void> {
    try {
      this.setViewport(true, "Code wird bestätigt und E-Mail wird gesendet …");

      const response = await ApiClient.postJson("Authentication/ConfirmUpdateAccountOldMail", this.confirmOldForm);
      this.message = fromResponse(response);
      this.oldMailConfirmed = response.success;
    } catch (e: unknown) {
      this.message = fail("Fehler beim Senden der Anfrage.");

      ErrorReporter.report(e as Error);
    } finally {
      this.setViewport(false);
    }
  }

  public async confirmNew(): Promise<void> {
    try {
      this.setGlobal(true, "Code wird bestätigt und E-Mail wird geändert …");

      const response = await ApiClient.postJson("Authentication/ConfirmUpdateAccountNewMail", this.confirmNewForm);
      if (!response.success) {
        this.message = fromResponse(response);

        return;
      }

      // this request being successful indicates the user has been logged out
      this.resetUser();

      Vue.$toast.success(response.message);

      await this.$router.push("/login");
    } catch (e: unknown) {
      this.message = fail("Fehler beim Senden der Anfrage.");

      ErrorReporter.report(e as Error);
    } finally {
      this.setGlobal(false);
    }
  }
}
