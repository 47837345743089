














































































import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import type {ApiMessage} from "@/api/models/common/ApiResponse";
import {useProductsStore} from "@/store/products";
import SmbToolbar from "@/components/ui/SmbToolbar/SmbToolbar.vue";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import SmbIconButton from "@/components/ui/SmbIconButton/SmbIconButton.vue";
import {mapActions} from "pinia";
import {ErrorReporter} from "@/api/client/ErrorReporter";
import {useBusyStore} from "@/store/busy";
import type {ComponentColor} from "@/components/ui/helpers";
import {fromResponse, handleApiError} from "@/api/helper";
import SmbDropdown from "@/components/ui/SmbDropdown/SmbDropdown.vue";
import SmbDropdownItem from "@/components/ui/SmbDropdown/SmbDropdownItem.vue";
import SmbCheckbox from "@/components/ui/SmbCheckbox/SmbCheckbox.vue";
import type {KeyKeeper} from "@/api/models/products/KeyKeeper";
import KeyKeeperColorSelect from "@/components/helper/KeyKeeperColorSelect.vue";
import {KeyKeeperColor} from "@/api/models/products/KeyKeeperColor";
import type {AddKeyKeeperPostForm} from "@/api/models/products/AddKeyKeeperPostForm";

@Component({
  components: {
    KeyKeeperColorSelect,
    SmbCheckbox,
    SmbDropdownItem,
    SmbDropdown,
    SmbIconButton,
    SmbTextInput,
    SmbApiAlert,
    SmbButton,
    SmbToolbar
  },
  metaInfo(this: EditKeyKeeper) {
    const newKeeper = this.isNewKeeper;

    return {
      title: newKeeper ? "Neues Gerät" : "KEYKEEPER Bearbeiten",
    };
  },
  methods: {
    ...mapActions(useProductsStore, ["createKeyKeeper", "updateKeyKeeper"]),
    ...mapActions(useBusyStore, ["setViewport"]),
  },
})
export default class EditKeyKeeper extends Vue {
  @Prop({type: Object, default: null})
  public readonly keeper!: KeyKeeper | null;

  public readonly createKeyKeeper!: (form: AddKeyKeeperPostForm) => Promise<ApiMessage>;
  public readonly updateKeyKeeper!: (keyKeeper: KeyKeeper) => Promise<ApiMessage>;
  public readonly setViewport!: (busy: boolean, message?: string, color?: ComponentColor) => void;

  public message: ApiMessage | null = null;

  public keyKeeperId: number | null = null;
  public activationCode = "";
  public keyKeeper: Omit<KeyKeeper, "id"> = {
    securityNumber: "",
    description: "",
    color: KeyKeeperColor.Black,
  };

  @Watch("keeper", {immediate: true})
  public onTagChanged(val: KeyKeeper | null): void {
    // fill attributes with data from prop
    this.keyKeeper = val ?? this.keyKeeper;
    this.keyKeeperId = val?.id ?? null;
  }

  public get isNewKeeper(): boolean {
    return !Number.isInteger(this.keeper?.id);
  }

  public async onSubmit(): Promise<void> {
    this.setViewport(true, "ID-TAG wird gespeichert...");

    try {
      if (this.isNewKeeper) {
        const newKeeper: AddKeyKeeperPostForm = Object.assign({}, this.keyKeeper, {activationCode: this.activationCode});
        const result = await this.createKeyKeeper(newKeeper);
        this.message = fromResponse(result);
        if (!result.success) {
          return;
        }

        await this.$router.push(`/products?msg=added_key_keeper`)
      } else {
        const editedKeeper: KeyKeeper = Object.assign({}, this.keyKeeper as KeyKeeper, {id: this.keyKeeperId});
        const result = await this.updateKeyKeeper(editedKeeper);
        this.message = fromResponse(result);
        if (!result.success) {
          return;
        }

        await this.$router.push(`/products/key-keeper/${editedKeeper.id}`)
      }
    } catch (e: unknown) {
      ErrorReporter.report(e as Error);

      this.message = await handleApiError(e as Error);
    } finally {
      this.setViewport(false);
    }
  }

  public onAbort(): void {
    this.$router.back();
  }
}
