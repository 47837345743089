import { fromResponse, handleApiError } from "@/api/helper";

import ApiClient from "@/api/client/ApiClient";
import type { ApiResponse } from "@/api/models/common/ApiResponse";
import type { Customer } from '@/api/models/customers/Customer';
import type { Device } from "@/api/models/devices/Device";
import type { Gender } from "@/api/models/auth/Gender";
import type { GetCustomerResponse } from "@/api/models/customers/GetCustomerResponse";
import type { GetCustomersResponse } from "@/api/models/customers/GetCustomersResponse";
import { defineStore } from "pinia";

export const useCustomerStore = defineStore({
    id: "customerStore",

    state() {
        return {
            customers: [] as Customer[],
            customer: null as Customer | null
        };
    },

    actions: {
        async tryGetCustomers(filter: string | null = null, force?: boolean): Promise<ApiResponse> {

            let apiCallUrl = "customers/GetCustomers";

            if (filter) {
                apiCallUrl = `customers/GetCustomers/${filter}`;
            }
            else if (this.customers.length > 0 && !force) {
                return {
                    success: true,
                    message: "",
                }
            }

            try {
                const response = await ApiClient.getJson<GetCustomersResponse>(
                    apiCallUrl
                );

                this.customers = response.customers;

                return fromResponse(response);
            } catch (e: unknown) {
                return await handleApiError(e as Error);
            }
        },

        async tryGetcustomerById(id: number): Promise<ApiResponse> {
            try {
                const response = await ApiClient.getJson<GetCustomerResponse>(
                    `customers/GetCustomer/${id}`
                );

                this.customer = {
                    BirthDay: response.birthDay as number,
                    BirthMonth: response.birthMonth  as number,
                    BirthYear: response.birthYear  as number,
                    City: response.city as string,
                    DeviceCount: response.deviceCount  as number,
                    Devices: response.devices as Device[],
                    Gender: response.gender  as Gender,
                    HouseNumber: response.houseNumber  as string,
                    Id: response.id  as number,
                    LoginId: response.loginId  as number,
                    Mail: response.mail  as string,
                    MobileNumber: response.mobileNumber  as string,
                    Name: response.name  as string,
                    Note: response.note  as string,
                    PhoneNumber: response.phoneNumber  as string,
                    Plz: response.postalCode  as string,
                    Prename: response.preName  as string,
                    Street: response.street  as string
                };

                return fromResponse(response);
            } catch (e: unknown) {
                return await handleApiError(e as Error);
            }
        },

        async getcustomerById(id: number): Promise<Customer> {
            await this.tryGetcustomerById(id);

            if (this.customer && typeof this.customer !== "undefined") {
                return this.customer;
            }

            throw new Error("Kunde nicht gefunden");
        },

    },
});
