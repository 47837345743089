import type { ApiMessage, ApiResponse } from "@/api/models/common/ApiResponse";

import type { ApiError } from "@/api/client/ApiError";
import { ErrorReporter } from "@/api/client/ErrorReporter";
import Vue from "vue";
import { useAuthStore } from "@/store/auth";

export type ApiMessageGenerator = (e: ApiError) => ApiMessage;
export const handleApiError = async (
  error: Error,
  generators: { [s: number]: ApiMessageGenerator } = {},
): Promise<ApiResponse> => {
  if (Object.prototype.hasOwnProperty.call(error, "response")) {
    const apiError = error as ApiError;
    const response = apiError.response;

    if (response.status === 429) {
      const message = "Du bist zu schnell! Bitte warte einen Moment, bevor du es wieder versuchst.";

      Vue.$toast.error(message);

      return fail(message);
    }

    if (response.status === 401) {
      const auth = useAuthStore();
      await auth.doLogout();

      const message = "Deine Sitzung ist leider abgelaufen. Bitte melde dich erneut an.";

      Vue.$toast.error(message);

      return fail(message);
    }

    const generator = generators[response.status];
    if (generator) {
      return generator(apiError);
    }

    try {
      const message = await response.json();

      return {
        success: false,
        message: message || apiError.message,
      };
    } catch (e: unknown) {
      return {
        success: false,
        message: "Der Server konnte die Anfrage nicht verarbeiten.",
      };
    }
  }

  ErrorReporter.report(error);

  return {
    success: false,
    message: "Es ist ein Fehler beim Senden der Anfrage aufgetreten.",
  };
};

export const fail = (message: string): ApiMessage => {
  return {
    success: false,
    message,
  };
};

export const success = (message: string): ApiMessage => {
  return {
    success: true,
    message,
  };
};

export const fromResponse = (response: ApiResponse): ApiMessage => {
  return {
    success: response.success,
    message: response.message,
  };
};
