






















































import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import SmbLoader from "@/components/ui/SmbLoader/SmbLoader.vue";
import { useLayoutStore } from "@/store/layout";
import { mapState } from "pinia";
import Vue from "vue";
import Component from "vue-class-component";
import type {MenuEntry} from "@/api/models/menu/MenuEntry";

@Component({
  components: { SmbLoader, SmbButton },
  computed: {
    ...mapState(useLayoutStore, {
      topMenuItems: store => store.menus.side.top,
      bottomMenuItems: store => store.menus.side.bottom,
    }),
  }
})
export default class DashboardMenu extends Vue {
  public readonly topMenuItems!: MenuEntry[];
  public readonly bottomMenuItems!: MenuEntry[];

  public get sortedTopMenuItems(): MenuEntry[] {
    return [...this.topMenuItems].sort(e => e.order);
  }

  public get sortedBottomMenuItems(): MenuEntry[] {
    return [...this.bottomMenuItems].sort(e => e.order);
  }
}
