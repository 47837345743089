













































































































































import type { ApiMessage, ApiResponse } from "@/api/models/common/ApiResponse";
import loadBeforeEnter from "@/components/helper/loadBeforeEnter";
import { useDevicesStore } from "@/store/devicesStore";
import Vue from "vue";
import Component from "vue-class-component";
import type { NavigationGuardNext, Route } from "vue-router";
import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import SmbSelect from "@/components/ui/SmbSelect/SmbSelect.vue";
import SmbToolbar from "@/components/ui/SmbToolbar/SmbToolbar.vue";
import type { Device } from "@/api/models/devices/Device";
import { DeviceState } from "@/api/models/devices/Device";
import type { ToolbarItem } from "@/components/ui/SmbToolbar/SmbToolbar.vue";
import { useBusyStore } from "@/store/busy";
import { fail, fromResponse, handleApiError } from "@/api/helper";
import { mapActions } from "pinia";
import ApiClient from "@/api/client/ApiClient";

@Component({
  components: { SmbApiAlert, SmbButton, SmbTextInput, SmbSelect, SmbToolbar },
  metaInfo: {
    title: "Geräte-Details",
  },
  methods: {
    ...mapActions(useBusyStore, ["setViewport"]),
  },
})
export default class DeviceDetails extends Vue {
  public device: Device | null = null;
  public message: ApiMessage | null = null;
  public readonly setViewport!: (busy: boolean, message?: string) => void;
  public isStateChangeReadonly = true;
  public readonly DeviceState = DeviceState;
  public oldDeviceState: DeviceState = DeviceState.Owned;

  public async beforeRouteEnter(
    to: Route,
    from: Route,
    next: NavigationGuardNext<this>
  ): Promise<void> {
    const deviceStore = useDevicesStore();

    await loadBeforeEnter(
      next,
      deviceStore.getDeviceById(Number(to.params.id)),
      (vm, device, error) => {
        vm.message = error;
        vm.device = device;
      }
    );
  }

  public get toolbarItems(): ToolbarItem[] {
    let items: ToolbarItem[] = [
      {
        text: "Zum Kundenprofil",
        icon: "user",
        click: async (): Promise<void> => {
          await this.$router.push(`/admin/customer/${this.device?.ClientId}`);
        },
      },
      // {
      //   text: "Fundmeldung anzeigen",
      //   icon: "history",
      //   click: async (): Promise<void> => {
      //     await this.$router.push(`/admin/customer/${this.device?.ClientId}`);
      //   },
      // },
      {
        text: "Status ändern",
        icon: "pen",
        click: async (): Promise<void> => {
          this.makeStateEditable(true);
        },
      },
    ];

    if (this.device?.Product.toUpperCase() === "SMARTPHONE") {
      items.push({
        text: "Laufzeit verlängern",
        icon: "plus",
        click: async (): Promise<void> => {
          return this.renewDuration();
        },
      });

      items.push({
        text: "Laufzeit beenden",
        icon: "times",
        click: async (): Promise<void> => {
          return this.expireDuration();
        },
      });
    }

    return items;
  }

  public makeStateEditable(editable: boolean): void {
    this.isStateChangeReadonly = !editable;

    if (this.device) {
      if (editable) {
        this.oldDeviceState = this.device.State;
      } else {
        this.device.State = this.oldDeviceState;
      }
    }
  }

  public async changeState(): Promise<void> {
    try {
      this.setViewport(true, "Status wird geändert …");
      let response = await this.tryChangeState();
      this.message = fromResponse(response);
      this.isStateChangeReadonly = true;
    } catch (e: unknown) {
      this.message = fail("Fehler beim Senden der Anfrage.");
    } finally {
      this.setViewport(false);
    }
  }

  public async tryChangeState(): Promise<ApiResponse> {
    try {
      return await ApiClient.postJson("device/ChangeDeviceState", {
        deviceId: this.device?.Id,
        deviceState: this.device?.State,
      });
    } catch (e: unknown) {
      return await handleApiError(e as Error);
    }
  }

  public async renewDuration(): Promise<void> {
    try {
      this.setViewport(true, "Laufzeit wird verlängert …");
      let response = await this.tryRenewDuration();
      this.message = fromResponse(response);
    } catch (e: unknown) {
      this.message = fail("Fehler beim Senden der Anfrage.");
    } finally {
      this.setViewport(false);
    }
  }

  public async tryRenewDuration(): Promise<ApiResponse> {
    try {
      return await ApiClient.postJson(
        `device/RenewDuration/${this.device?.Id}`
      );
    } catch (e: unknown) {
      return await handleApiError(e as Error);
    }
  }

  public async expireDuration(): Promise<void> {
    try {
      this.setViewport(true, "Laufzeit wird beendet …");
      let response = await this.tryExpireDuration();
      this.message = fromResponse(response);
    } catch (e: unknown) {
      this.message = fail("Fehler beim Senden der Anfrage.");
    } finally {
      this.setViewport(false);
    }
  }

  public async tryExpireDuration(): Promise<ApiResponse> {
    try {
      return await ApiClient.postJson(
        `device/ExpireDuration/${this.device?.Id}`
      );
    } catch (e: unknown) {
      return await handleApiError(e as Error);
    }
  }
}
