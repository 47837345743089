















































































import Vue from "vue";
import Component from "vue-class-component";
import type {ApiMessage} from "@/api/models/common/ApiResponse";
import type {RequestDeleteAccountPostForm} from "@/api/models/account/RequestDeleteAccountPostForm";
import type {ConfirmDeleteAccountPostForm} from "@/api/models/account/ConfirmDeleteAccountPostForm";
import type {ComponentColor} from "@/components/ui/helpers";
import ApiClient from "@/api/client/ApiClient";
import {fail, fromResponse} from "@/api/helper";
import {ErrorReporter} from "@/api/client/ErrorReporter";
import SmbCodeInput from "@/components/ui/SmbCodeInput/SmbCodeInput.vue";
import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import SmbCheckbox from "@/components/ui/SmbCheckbox/SmbCheckbox.vue";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import {mapActions, mapState} from "pinia";
import {useAuthStore} from "@/store/auth";
import {useBusyStore} from "@/store/busy";

@Component({
  components: {SmbCodeInput, SmbApiAlert, SmbCheckbox, SmbButton, SmbTextInput},
  computed: {
    ...mapState(useAuthStore, {
      currentMail: state => state.user?.mail ?? undefined,
    }),
  },
  methods: {
    ...mapActions(useBusyStore, ["setViewport", "setGlobal"]),
    ...mapActions(useAuthStore, ["resetUser"]),
  }
})
export default class DeleteAccountForm extends Vue {
  public readonly currentMail!: string | undefined;
  public readonly setViewport!: (show: boolean, message?: string, color?: ComponentColor) => void;
  public readonly setGlobal!: (show: boolean, message?: string, color?: ComponentColor) => void;
  public readonly resetUser!: () => void;

  public message: ApiMessage | null = null;
  public requestForm: RequestDeleteAccountPostForm = {
    password: "",
  };
  public confirmed = false;
  public requested = false;
  public confirmForm: ConfirmDeleteAccountPostForm = {
    code: "",
  };

  public async request(): Promise<void> {
    try {
      this.setViewport(true, "Anfrage wird gesendet …");

      const response = await ApiClient.postJson("Authentication/RequestAccountDeletion", this.requestForm);
      this.message = fromResponse(response);

      if (response.success) {
        this.requested = true;
      }
    } catch (e: unknown) {
      this.message = fail("Fehler beim Senden der Anfrage.");

      ErrorReporter.report(e as Error);
    } finally {
      this.setViewport(false);
    }
  }

  public async confirm(): Promise<void> {
    try {
      this.setGlobal(true, "Konto wird gelöscht …", "danger");

      const response = await ApiClient.postJson("Authentication/ConfirmAccountDeletion", this.confirmForm);
      if (!response.success) {
        this.message = fromResponse(response);

        return;
      }

      // this request being successful indicates the user has been logged out
      this.resetUser();

      Vue.$toast.success(response.message);

      await this.$router.push("/login");
    } catch (e: unknown) {
      this.message = fail("Fehler beim Senden der Anfrage.");

      ErrorReporter.report(e as Error);
    } finally {
      this.setGlobal(false);
    }
  }
}
