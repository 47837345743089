































































import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import type {ApiMessage} from "@/api/models/common/ApiResponse";
import type {NavigationGuardNext, Route} from "vue-router";
import type {ToolbarItem} from "@/components/ui/SmbToolbar/SmbToolbar.vue";
import SmbToolbar from "@/components/ui/SmbToolbar/SmbToolbar.vue";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import SmbIconButton from "@/components/ui/SmbIconButton/SmbIconButton.vue";
import IdTagColorSelect from "@/components/helper/IdTagColorSelect.vue";
import {mapActions} from "pinia";
import {useBusyStore} from "@/store/busy";
import type {ComponentColor} from "@/components/ui/helpers";
import SmbDropdown from "@/components/ui/SmbDropdown/SmbDropdown.vue";
import SmbDropdownItem from "@/components/ui/SmbDropdown/SmbDropdownItem.vue";
import EditIdTag from "@/views/customer/products/id-tag/EditIdTag.vue";
import type {IdTag} from "@/api/models/products/IdTag";
import {useProductsStore} from "@/store/products";
import loadBeforeEnter from "@/components/helper/loadBeforeEnter";

@Component({
  components: {
    EditIdTag,
    SmbDropdownItem,
    SmbDropdown,
    IdTagColorSelect,
    SmbIconButton,
    SmbTextInput,
    SmbApiAlert,
    SmbButton,
    SmbToolbar
  },
  methods: {
    ...mapActions(useBusyStore, ["setViewport"]),
  },
})
export default class IdTagLayout extends Vue {
  @Prop({type: String, default: null})
  public readonly id!: string | null;

  public readonly setViewport!: (busy: boolean, message?: string, color?: ComponentColor) => void;

  public message: ApiMessage | null = null;
  public tag: IdTag | null = null;
  public actionsMenuOpen = false;

  public get isNewTag(): boolean {
    return this.id === null;
  }

  public async beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<this>): Promise<void> {
    if (to.name === "add-id-tag") {
      next((vm) => {
        vm.tag = null;
      });

      return;
    }

    const products = useProductsStore();
    await loadBeforeEnter(
      next,
      products.getIdTagById(Number(to.params.id)),
      (vm, idTag, error) => {
        vm.message = error;
        vm.tag = idTag;
      },
    );
  }

  public get toolbarItems(): ToolbarItem[] {
    let items: ToolbarItem[] = [
      {
        text: "Ansicht",
        icon: "eye",
        click: async (): Promise<void> => {
          if (this.$route.name !== "view-id-tag")
            await this.$router.push(`/products/id-tag/${this.tag?.id}`);
        },
      },
      {
        text: "Verlust",
        icon: "lock",
       click: async (): Promise<void> => {
          if (this.$route.name !== "lost-id-tag")
            await this.$router.push(`/products/id-tag/${this.tag?.id}/reportlost`);
        },
      }
    ];

    if (this.tag?.isSmartphone) {
      items.push({
        text: "Orten",
        icon: "map-marker-alt",
        click: async (): Promise<void> => {
          if (this.$route.name !== "locate-id-tag")
            await this.$router.push(`/products/id-tag/${this.tag?.id}/locate`);
        },
      });
    }

    items.push(
      // {
      //   text: "Übertragen",
      //   icon: "share",
      //   click: (): void => {
      //     alert("Übertragen");
      //   },
      // },
      // {
      //   text: "Verlängern",
      //   topContent: "+2",
      //   click: (): void => {
      //     alert("Verlängern");
      //   },
      // },
      {
        text: "Löschen",
        icon: "trash",
        click: async (): Promise<void> => {
          if (this.$route.name !== "delete-id-tag")
            await this.$router.push(`/products/id-tag/${this.tag?.id}/delete`);
        },
      },
      {
        text: "Bearbeiten",
        icon: "pen",
        click: async (): Promise<void> => {
          if (this.$route.name !== "edit-id-tag")
            await this.$router.push(`/products/id-tag/${this.tag?.id}/edit`);
        },
      },
    );

    return items;
  }
}
