















































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import type { ApiMessage } from "@/api/models/common/ApiResponse";
import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import type { BlogEntry } from "@/api/models/blog/BlogEntry";
import { useBlogStore } from "@/store/blog";
import type { NavigationGuardNext, Route } from "vue-router";
import loadBeforeEnter from "@/components/helper/loadBeforeEnter";

@Component({
  components: { SmbApiAlert },
  metaInfo(this: BlogEntryView) {
    const title = this.post?.title ?? null;

    return {
      title: title !== null ? title : "Blog",
    };
  },
})
export default class BlogEntryView extends Vue {
  @Prop({ type: String, required: true })
  public readonly slug!: string;

  @Ref()
  public readonly topLink!: HTMLAnchorElement;

  public error: null | ApiMessage = null;
  public post: null | BlogEntry = null;

  public async beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<this>): Promise<void> {
    const blog = useBlogStore();
    await loadBeforeEnter(
      next,
      blog.getBySlug(to.params.slug),
      (vm, post, error) => {
        vm.error = error;
        vm.post = post;
      }
    );
  }

  public mounted(): void {
    window.scrollTo(0, this.topLink.offsetTop);
  }
}
