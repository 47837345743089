

























































import Vue from "vue";
import Component from "vue-class-component";
import DraggableContainer from "@/components/helper/DraggableContainer.vue";
import IdTagSticker from "@/components/helper/IdTagSticker.vue";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import type {IdTag} from "@/api/models/products/IdTag";
import {Prop} from "vue-property-decorator";

@Component({
  components: {IdTagSticker, DraggableContainer, SmbButton}
})
export default class IdTagCards extends Vue {
  @Prop({type: Array, required: true})
  public readonly idTags!: IdTag[];
}
