export interface Device {
    [key: string]: unknown;

    Id: number;
    ClientId: number;
    SecurityNumber: number;
    Product: string;
    Description: string;
    ActivUntil: Date;
    SerialNumber: number;
    Manufacturer: string;
    Model: string;
    State: DeviceState;
}

export enum DeviceState {
    Owned = "owned",
    Lost = "lost",
    Found = "found",
    Return = "return",
    Progress = "progress",
    Closed = "closed",
  }
  