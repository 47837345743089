
























































import { fail } from "@/api/helper";
import type { ApiMessage, ApiResponse } from "@/api/models/common/ApiResponse";
import type { ComponentColor } from "@/components/ui/helpers";
import Vue from "vue";
import Component from "vue-class-component";
import type { CellDefinition } from "@/components/ui/SmbTable/SmbTable.vue";
import SmbTable from "@/components/ui/SmbTable/SmbTable.vue";
import { useBusyStore } from "@/store/busy";
import { useCustomerStore } from "@/store/customerStore";
import { mapActions, mapState } from "pinia";
import type { Customer } from "@/api/models/customers/Customer";
import type { RawLocation } from "vue-router";
import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";

@Component({
  components: {
    SmbTable,
    SmbTextInput,
    SmbButton,
  },
  metaInfo: {
    title: "Kundenübersicht",
  },
  methods: {
    ...mapActions(useBusyStore, { setBusyViewport: "setViewport" }),
    ...mapActions(useCustomerStore, ["tryGetCustomers"]),
  },
  computed: {
    ...mapState(useCustomerStore, ["customers"]),
  },
})
export default class Customers extends Vue {
  public readonly tryGetCustomers!: (
    filter?: string | null,
    force?: boolean
  ) => Promise<ApiResponse>;
  public readonly setBusyViewport!: (
    busy: boolean,
    message?: string,
    color?: ComponentColor
  ) => void;
  public readonly viewMode!: string;
  public readonly customers!: Customer[];

  public message: ApiMessage | null = null;
  public sortCol = "";
  public sortCheckNumber1 = -1;
  public sortCheckNumber2 = 1;

  public filter: string | null = null;

  public async mounted(): Promise<void> {
    await this.getCustomers();
  }

  public async getCustomers(filter: string | null = null, force = false): Promise<void> {
    try {
      this.setBusyViewport(true, "Lade Kunden …");
      this.message = await this.tryGetCustomers(filter, force);
    } catch (e: unknown) {
      this.message = fail("Fehler beim Senden der Anfrage.");
    } finally {
      this.setBusyViewport(false);
    }
  }

  public customerCellRenderer(customer: Customer, key: string): CellDefinition {
    return {
      tag: "span",
      slot: customer[key],
    };
  }

  public customerHeaderClicked(col: string): void {
    if (this.sortCol === col) {
      this.sortCheckNumber1 *= -1;
      this.sortCheckNumber2 *= -1;
    } else {
      this.sortCheckNumber1 = -1;
      this.sortCheckNumber2 = 1;
    }

    this.sortCol = col;

    this.customers.sort((a, b) => {
      if ((a[col] as string) < (b[col] as string)) return this.sortCheckNumber1;

      if ((a[col] as string) > (b[col] as string)) return this.sortCheckNumber2;

      return 0;
    });
  }

  public async customerRowClicked(row: Customer): Promise<void> {
    await this.$router.push(this.getLinkTo(row));
    return;
  }

  public getLinkTo(customer: Customer): RawLocation {
    return `customer/${customer.id}`;
  }

  public async callFilter(): Promise<void> {
    return this.getCustomers(this.filter);
  }

  public async cancelFilter(): Promise<void> {
    this.filter = null;
    return this.getCustomers(null, true);
  }
}
