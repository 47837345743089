var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:"id-tag-table",staticClass:"h-100"},[_c('smb-button',{staticClass:"px-5 mb-3 d-inline-block w-auto",attrs:{"color":"success","to":"/products/id-tag/add"}},[_c('fa-icon',{attrs:{"icon":"plus"}}),_vm._v(" ID-TAG registrieren ")],1),_c('smb-table',{staticClass:"text-small",staticStyle:{"cursor":"pointer"},attrs:{"data":_vm.idTags,"headers":{
      'icon': {
        tag: 'product-icon',
        attrs: {
          icon: _vm.idTagIconColHeaderIcon,
          color: 'white',
          size: '3x',
        }
      },
      'securityNumber': 'Sicherheitscode',
      'description': 'Bezeichnung',
      'manufacturer': 'Hersteller',
      'model': 'Modell',
      'activeUntil': 'Aktiv bis',
    },"cell-renderer":_vm.idTagCellRenderer,"empty-text":"Keine ID-TAGs gefunden"},on:{"row-clicked":_vm.idTagRowClicked,"header-clicked":_vm.idTagHeaderClicked}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }