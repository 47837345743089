


























































import { ErrorReporter } from "@/api/client/ErrorReporter";
import { fail, fromResponse } from "@/api/helper";
import type { LoginPostForm } from "@/api/models/auth/LoginPostForm";
import { UserRole } from "@/api/models/auth/UserRole";
import type { ApiMessage } from "@/api/models/common/ApiResponse";
import AuthLayout from "@/components/layout/AuthLayout.vue";
import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import { useAuthStore } from "@/store/auth";
import { useBusyStore } from "@/store/busy";
import { mapActions, mapState } from "pinia";
import Vue from "vue";
import Component from "vue-class-component";
import type { RawLocation } from "vue-router";
import { Ref } from "vue-property-decorator";

const LoginProps = Vue.extend({
  computed: {
    ...mapState(useAuthStore, [
      "isAuthenticated",
      "authenticatedUser",
      "registrationCompleted",
    ]),
  },
  methods: {
    ...mapActions(useAuthStore, ["tryAuthenticate", "doLogin"]),
  },
});

@Component({
  components: {
    AuthLayout,
    SmbApiAlert,
    SmbButton,
    SmbTextInput,
  },
  metaInfo: {
    title: "Anmelden",
  },
})
export default class Login extends LoginProps {
  @Ref() public readonly passwordField!: SmbTextInput;

  public form: LoginPostForm = {
    mail: "",
    password: "",
  };

  public processing = false;
  public errorMessage: ApiMessage | null = null;

  public async created(): Promise<void> {
    if (this.isAuthenticated) {
      const busy = useBusyStore();
      busy.setGlobal(true, "Du wirst weitergeleitet...");

      await this.postLoginRedirect();

      busy.setGlobal(false);
    }
  }

  public async login(): Promise<void> {
    const busy = useBusyStore();
    try {
      busy.setGlobal(true, "Du wirst eingeloggt …");

      const response = await this.doLogin(this.form);
      if (response.success) {
        busy.setGlobal(true, response.message);

        await this.postLoginRedirect();
      } else {
        this.errorMessage = fromResponse(response);
      }
    } catch (e: unknown) {
      ErrorReporter.report(e as Error);

      this.errorMessage = fail((e as Error).message);
    } finally {
      busy.setGlobal(false);
    }
  }

  public async postLoginRedirect(): Promise<void> {
    let redirect: RawLocation;

    switch (this.authenticatedUser.role) {
      case UserRole.Admin:
        redirect = "/admin/dashboard";
        break;
      case UserRole.Customer:
      default:
        redirect = "/";
        break;
    }

    if (this.$route.query.r) {
      redirect = decodeURIComponent(this.$route.query.r as string);
    }

    if (!this.registrationCompleted) {
      redirect = "/register";
    }

    await this.$router.push(redirect);
  }
}
