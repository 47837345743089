import { fromResponse, handleApiError } from "@/api/helper";

import ApiClient from "@/api/client/ApiClient";
import type { ApiResponse } from "@/api/models/common/ApiResponse";
import type { Device } from '@/api/models/devices/Device';
import type { DeviceState } from './../api/models/devices/Device';
import type { GetDeviceResponse } from './../api/models/devices/GetDeviceResponse';
import type { GetDevicesResponse } from "@/api/models/devices/GetDevicesResponse";
import { defineStore } from "pinia";

export const useDevicesStore = defineStore({
    id: "devicesStore",

    state() {
        return {
            devices: [] as Device[],
            customerDevices: [] as Device[],
            device: null as Device | null,
        };
    },

    actions: {
        async tryGetDevices(filter: string | null = null, force?: boolean): Promise<ApiResponse> {

            let apiCallUrl = "device/GetDevices";

            if (filter) {
                apiCallUrl = `device/GetDevices/${filter}`;
            }
            else if (this.devices.length > 0 && !force) {
                return {
                    success: true,
                    message: "",
                }
            }

            try {
                const response = await ApiClient.getJson<GetDevicesResponse>(
                    apiCallUrl
                );

                this.devices = response.devices;

                return fromResponse(response);
            } catch (e: unknown) {
                return await handleApiError(e as Error);
            }
        },

        async tryGetDeviceById(id: number): Promise<ApiResponse> {
            try {
                const response = await ApiClient.getJson<GetDeviceResponse>(
                    `device/GetDevice/${id}`
                );

                this.device = {
                    ActivUntil: response.activeUntil as Date,
                    ClientId: response.clientId as number,
                    Description: response.description as string,
                    Id: response.id as number,
                    Manufacturer: response.manufacturer as string,
                    Model: response.model as string,
                    Product: response.product as string,
                    SecurityNumber: response.securityNumber as number,
                    SerialNumber: response.serialNumber as number,
                    State: response.state as DeviceState
                };

                return fromResponse(response);
            } catch (e: unknown) {
                return await handleApiError(e as Error);
            }
        },

        async getDeviceById(id: number): Promise<Device> {
            await this.tryGetDeviceById(id);

            if (this.device && typeof this.device !== "undefined")
                return this.device;

            throw new Error("Gerät nicht gefunden");
        },

        async tryGetDevicesByCustomerId(id: number): Promise<ApiResponse> {
            try {
                const response = await ApiClient.getJson<GetDevicesResponse>(
                    `device/GetDevicesByCustomerId/${id}`
                );

                this.customerDevices = response.devices;

                return fromResponse(response);
            } catch (e: unknown) {
                return await handleApiError(e as Error);
            }
        },

        async getDevicesByCustomerId(id: number): Promise<Device[]> {
            await this.tryGetDevicesByCustomerId(id);

            if (typeof this.customerDevices !== "undefined")
                return this.customerDevices;

            throw new Error("Geräte nicht gefunden");
        },
    },
});
