import { fail, handleApiError } from "@/api/helper";

import ApiClient from "@/api/client/ApiClient";
import type { ApiResponse } from "@/api/models/common/ApiResponse";
import type { ConfirmMailPostForm } from "@/api/models/auth/ConfirmMailPostForm";
import type { ConfirmMailResponse } from "@/api/models/auth/ConfirmMailResponse";
import type { LoginPostForm } from "@/api/models/auth/LoginPostForm";
import type { LoginResponse } from "@/api/models/auth/LoginResponse";
import type { RegisterPostForm } from "@/api/models/auth/RegisterPostForm";
import type { RegisterResponse } from "@/api/models/auth/RegisterResponse";
import type { User } from "@/api/models/auth/User";
import type { UserResponse } from "@/api/models/auth/UserResponse";
import { UserRole } from "@/api/models/auth/UserRole";
import { defineStore } from "pinia";
import { useLayoutStore } from "@/store/layout";
import { useSettingsStore } from "@/store/settings";

export const useAuthStore = defineStore({
  id: "auth",

  state() {
    return {
      user: null as User | null,
      loggingOut: false,
      mailConfirmed: false,
      profileCompleted: false,
    };
  },

  getters: {
    isAuthenticated(): boolean {
      return !this.loggingOut && this.user !== null;
    },

    authenticatedUser(): User {
      if (!this.user) {
        throw new Error("No user is authenticated!");
      }

      return this.user;
    },

    registrationCompleted(): boolean {
      return this.mailConfirmed && this.profileCompleted;
    },
  },

  actions: {
    async doLogout(): Promise<void> {
      this.loggingOut = true;

      try {
        await ApiClient.post("Authentication/Logout");
      } catch (e: unknown) {
        // maybe the user's session expired
        // try to refresh using the refresh token and explicitly log out again

        if (await this.tryRefreshToken()) {
          try {
            await ApiClient.post("Authentication/Logout");
          } catch (e: unknown) {
            // user had no valid access token and no valid refresh token
            // unable to explicitly log out, but should also be unable to login in this state
            // therefore ignore the exception
          }
        }
      } finally {
        this.resetUser();

        this.loggingOut = false;
      }
    },

    async doLogin(credentials: LoginPostForm): Promise<ApiResponse> {
      try {
        const loginResponse = await ApiClient.postJson<LoginResponse>(
          "Authentication/Login",
          credentials,
        );

        const settings = useSettingsStore();
        settings.messages.welcome = loginResponse.firstLogin;

        this.user = await this.tryGetUser();
        if (this.user) {
          const layout = useLayoutStore();
          layout.setMenusByRole(this.user.role);
        }

        return loginResponse;
      } catch (e: unknown) {
        return await handleApiError(e as Error);
      }
    },

    async doCreateAccount(form: RegisterPostForm): Promise<ApiResponse> {
      try {
        return await ApiClient.postJson<RegisterResponse>(
          "Authentication/Register",
          form,
        );
      } catch (e: unknown) {
        return await handleApiError(e as Error);
      }
    },

    async doConfirmMail(form: ConfirmMailPostForm): Promise<ApiResponse> {
      try {
        return await ApiClient.postJson<ConfirmMailResponse>(
          "Authentication/RegisterConfirm",
          form,
        );
      } catch (e: unknown) {
        return await handleApiError(e as Error);
      }
    },

    async doResendCode(): Promise<ApiResponse> {
      try {
        return await ApiClient.getJson(
          "Authentication/ResendMailValidation",
        );
      } catch (e: unknown) {
        return handleApiError(e as Error, {
          401: () =>
            fail(
              "Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.",
            ),
        });
      }
    },

    resetUser(): void {
      const layout = useLayoutStore();
      layout.resetMenus();

      this.user = null;
    },

    async tryAuthenticate(): Promise<void> {
      try {
        const layout = useLayoutStore();
        layout.resetMenus();

        this.user = await this.tryGetUser();
        if (this.user) {
          layout.setMenusByRole(this.user.role);
        }
      } catch (e: unknown) {
        this.user = null;
      }
    },

    async tryRefreshToken(): Promise<boolean> {
      try {
        const response = await ApiClient.postJson(
          "Authentication/Refresh",
          undefined,
          false,
        );

        return response.success;
      } catch (e: unknown) {
        return false;
      }
    },

    async tryGetUser(implicitRefresh = true): Promise<User | null> {
      try {
        const userResponse = await ApiClient.getJson<UserResponse>(
          "Authentication/User",
          implicitRefresh,
        );

        if (!userResponse.success) {
          return null;
        }

        this.mailConfirmed = userResponse.isMailVerified;
        this.profileCompleted = userResponse.isRegistrationCompleted;

        return {
          mail: userResponse.mail,
          role: userResponse.userRole,
          lastLogin: userResponse.lastLogin,
        };
      } catch (e: unknown) {
        return null;
      }
    },

    hasRole(role: UserRole): boolean {
      if (!this.isAuthenticated) {
        return role === UserRole.Guest;
      }

      return role === this.authenticatedUser.role;
    }
  },
});
