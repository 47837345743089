












































import Vue from "vue";
import Component from "vue-class-component";
import { PasswordRegex } from "../ui/helpers";

const PasswordChecklistProps = Vue.extend({
  props: {
    password: {
      type: String,
      default: null,
      validator: (val: unknown) => val === null || typeof val === "string",
    },
  },
});

@Component
export default class PasswordChecklist extends PasswordChecklistProps {
  public passwordRegex = PasswordRegex;

  private get passwordText(): string {
    return this.password as string;
  }

  private get passwordTruthy(): boolean {
    return Boolean(this.password);
  }

  public get lengthOk(): boolean {
    return this.passwordTruthy && this.passwordText.length > 8;
  }

  public get uppercaseOk(): boolean {
    return this.passwordTruthy && /^(?=.*?[A-Z]).*$/.test(this.passwordText);
  }

  public get lowercaseOk(): boolean {
    return this.passwordTruthy && /^(?=.*?[a-z]).*$/.test(this.passwordText);
  }

  public get numberOk(): boolean {
    return this.passwordTruthy && /^(?=.*?[0-9]).*$/.test(this.passwordText);
  }

  public get specialOk(): boolean {
    return this.passwordTruthy && /^(?=.*?[#?!@$ %^&*\-_.]).*$/.test(this.passwordText);
  }

  public get allOk(): boolean {
    if (!this.passwordTruthy) {
      return false;
    }

    const overallRegex = this.passwordRegex.test(this.passwordText);
    if (!overallRegex) {
      return false;
    }

    if (!this.lengthOk || !this.uppercaseOk || !this.lowercaseOk || !this.numberOk || !this.specialOk) {
      throw new Error("One of the password patterns is not ok while the overall pattern is successful. Are the patterns up to date?");
    }

    return true;
  }
}
