











import Vue from "vue";
import Component from "vue-class-component";

@Component({
  metaInfo: {
    title: "Übertragungen",
  },
})
export default class Transfer extends Vue {}
