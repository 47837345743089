














import Vue from "vue";
import Component from "vue-class-component";
import { Ref } from "vue-property-decorator";

@Component
export default class DraggableContainer extends Vue {
  @Ref()
  public readonly container!: HTMLDivElement;

  public grabbing = false;
  public top = 0;
  public left = 0;
  public x = 0;
  public y = 0;

  public mouseDown(e: MouseEvent): void {
    this.top = this.container.scrollTop;
    this.left = this.container.scrollLeft;
    this.x = e.clientX;
    this.y = e.clientY;
    this.grabbing = true;
  }

  public mouseUp(): void {
    this.grabbing = false;
  }

  public mouseLeave(): void {
    this.grabbing = false;
  }

  public mouseMove(e: MouseEvent): void {
    if (!this.grabbing)
      return;

    const dx = e.clientX - this.x;
    const dy = e.clientY - this.y;

    this.container.scrollTop = this.top - dy;
    this.container.scrollLeft = this.left - dx;
  }
}
