


















































































import Vue from "vue";
import Component from "vue-class-component";
import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import PasswordChecklist from "@/components/helper/PasswordChecklist.vue";
import type {ComponentColor} from "@/components/ui/helpers";
import { PasswordRegex} from "@/components/ui/helpers";
import {Ref, Watch} from "vue-property-decorator";
import type {UpdateUserAccountPasswordPostForm} from "@/api/models/account/UpdateUserAccountPasswordPostForm";
import type {ApiMessage} from "@/api/models/common/ApiResponse";
import {fail, fromResponse} from "@/api/helper";
import ApiClient from "@/api/client/ApiClient";
import {ErrorReporter} from "@/api/client/ErrorReporter";
import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import {mapActions, mapState} from "pinia";
import {useAuthStore} from "@/store/auth";
import {useBusyStore} from "@/store/busy";

@Component({
  components: {SmbTextInput, SmbButton, SmbApiAlert, PasswordChecklist},
  computed: {
    ...mapState(useAuthStore, {
      currentMail: state => state.user?.mail ?? undefined,
    }),
  },
  methods: {
    ...mapActions(useBusyStore, ["setViewport"]),
  }
})
export default class ChangePasswordForm extends Vue {
  @Ref() public readonly changePasswordField!: SmbTextInput;
  @Ref() public readonly changePasswordConfirmField!: SmbTextInput;
  public readonly currentMail!: string | undefined;
  public readonly setViewport!: (show: boolean, message?: string, color?: ComponentColor) => void;

  public readonly passwordRegex = PasswordRegex;

  public message: ApiMessage | null = null;
  public form: UpdateUserAccountPasswordPostForm = {
    password: "",
    newPassword: "",
  };
  public confirm = "";
  public showHint = false;
  public passwordFieldFocus = false;
  public passwordConfirmFieldFocus = false;

  @Watch("passwordFieldFocus")
  public onPasswordFieldFocus(val: boolean): void {
    setTimeout(() => this.showHint = val || this.passwordConfirmFieldFocus, 100);
  }

  @Watch("passwordConfirmFieldFocus")
  public onPasswordConfirmFieldFocus(val: boolean): void {
    setTimeout(() => this.showHint = val || this.passwordFieldFocus, 100);
  }

  public async submitUpdatePassword(): Promise<void> {
    try {
      this.setViewport(true, "Passwort wird geändert …");

      if (this.form.newPassword !== this.confirm) {
        this.message = fail("Die eingegebenen Passwörter müssen übereinstimmen!");

        return;
      }

      const response = await ApiClient.postJson("Authentication/UpdateUserAccountPassword", this.form);
      this.message = fromResponse(response);

      // reset values
      this.form.password = "";
      if (response.success) {
        this.form.newPassword = "";
        this.confirm = "";
      }
    } catch (e: unknown) {
      this.message = fail("Fehler beim Senden der Anfrage.");

      ErrorReporter.report(e as Error);
    } finally {
      this.setViewport(false);
    }
  }
}
