











import Vue from "vue";
import Component from "vue-class-component";

@Component({
  metaInfo: {
    title: "E-Mail-Verwaltung",
  },
})
export default class MailManagement extends Vue {}
