






































































































































import type { ApiMessage, ApiResponse } from "@/api/models/common/ApiResponse";
import type {
  SecurityNumberOrder,
  ActivationCodeOrder,
  Order,
} from "@/api/models/orders/Order";
import type { GetOrdersResponse } from "@/api/models/orders/GetOrdersResponse";
import Vue from "vue";
import Component from "vue-class-component";
import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import SmbSelect from "@/components/ui/SmbSelect/SmbSelect.vue";
import SmbTable from "@/components/ui/SmbTable/SmbTable.vue";
import { Product } from "@/api/models/orders/Product";
import { ProductColor } from "@/api/models/orders/ProductColor";
import type { CellDefinition } from "@/components/ui/SmbTable/SmbTable.vue";
import dayjs from "dayjs";
import { fail, fromResponse, handleApiError } from "@/api/helper";
import ApiClient from "@/api/client/ApiClient";
import { useBusyStore } from "@/store/busy";
import { mapActions } from "pinia";

@Component({
  components: { SmbApiAlert, SmbButton, SmbTextInput, SmbSelect, SmbTable },
  metaInfo: {
    title: "Bestellübersicht",
  },
  methods: {
    ...mapActions(useBusyStore, ["setViewport"]),
  },
})
export default class Orders extends Vue {
  public message: ApiMessage | null = null;
  public readonly setViewport!: (busy: boolean, message?: string) => void;
  public orders: Order[] = [];

  public securityNumberOrder: SecurityNumberOrder = {
    SecuritynumberCount: "",
    Product: Product.IDTAG,
    ProductColor: ProductColor.Black,
  };

  public activationCodeOrder: ActivationCodeOrder = {
    ActivationCodesCount: "",
  };

  public readonly Product = Product;
  public readonly ProductColor = ProductColor;

  public async mounted(): Promise<void> {
    await this.getOrders();
  }

  public async getOrders(): Promise<void> {
    try {
      this.setViewport(true, "Lade Bestellungen …");
      await this.tryGetOrders();
    } catch (e: unknown) {
      this.message = fail("Fehler beim Senden der Anfrage.");
    } finally {
      this.setViewport(false);
    }
  }

  public async tryGetOrders(): Promise<ApiResponse> {
    try {
      const response = await ApiClient.getJson<GetOrdersResponse>(
        "device/GetOrders"
      );

      this.orders = response.orders;

      return fromResponse(response);
    } catch (e: unknown) {
      return await handleApiError(e as Error);
    }
  }

  public async createSecurityNumbers(): Promise<void> {
    try {
      this.setViewport(true, "Status wird geändert …");
      let response = await this.tryCreateSecurityNumbers();
      await this.tryGetOrders();
      this.message = fromResponse(response);
    } catch (e: unknown) {
      this.message = fail("Fehler beim Senden der Anfrage.");
    } finally {
      this.setViewport(false);
    }
  }

  public async tryCreateSecurityNumbers(): Promise<ApiResponse> {
    try {
      return await ApiClient.postJson("device/CreateSecurityNumbers", {
        SecuritynumberCount: this.securityNumberOrder.SecuritynumberCount,
        Product: this.securityNumberOrder.Product,
        ProductColor: this.securityNumberOrder.ProductColor,
      });
    } catch (e: unknown) {
      return await handleApiError(e as Error);
    }
  }

  public async createActivationCodes(): Promise<void> {
    try {
      this.setViewport(true, "Status wird geändert …");
      let response = await this.trycreateActivationCodes();
      await this.tryGetOrders();
      this.message = fromResponse(response);
    } catch (e: unknown) {
      this.message = fail("Fehler beim Senden der Anfrage.");
    } finally {
      this.setViewport(false);
    }
  }

  public async trycreateActivationCodes(): Promise<ApiResponse> {
    try {
      return await ApiClient.postJson("device/CreateActivationCodes", {
        ActivationCodesCount: this.activationCodeOrder.ActivationCodesCount,
      });
    } catch (e: unknown) {
      return await handleApiError(e as Error);
    }
  }

  public ordersCellRenderer(order: Order, key: string): CellDefinition {

    switch (key.toUpperCase()) {
      case "ORDERDATE":
        return {
          tag: "span",
          slot: dayjs(order[key] as Date).format("DD.MM.YYYY"),
        };
      case "DOWNLOADLINK":
        return {
          tag: "a",
          slot: "Download",
          attrs: {
            href: order[key],
            target: "_blank",
          },
        };
      default:
        return {
          tag: "span",
          slot: order[key],
        };
    }
  }
}
