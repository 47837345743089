













import Vue from "vue";
import Component from "vue-class-component";
import { colorValidator } from "@/components/ui/helpers";
import { Model, Prop } from "vue-property-decorator";

@Component
export default class SmbAlert extends Vue {
  @Model('close', { type: Boolean, default: true })
  public readonly visible!: boolean;

  @Prop({ type: String, default: undefined, validator: colorValidator() })
  public readonly color!: string;

  @Prop({ type: Boolean, default: true })
  public readonly closeable!: boolean;

  @Prop({ type: String, default: null, validator: (val: unknown) => val === null || typeof val === "string" })
  public readonly title!: string;

  public get classNames(): string {
    const classes = ["smb-alert"];

    if (this.color) {
      classes.push(this.color);
    }

    if (!this.visible) {
      classes.push("d-none");
    }

    return classes.join(" ");
  }

  public close(): void {
    this.$emit("close", false);
  }
}
