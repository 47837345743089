








































































import type { ComponentColor } from "@/components/ui/helpers";
import { anyOf } from "@/components/ui/helpers";
import type { SmbInput } from "@/components/ui/SmbInput";
import Vue from "vue";
import Component from "vue-class-component";
import { ModelSync, Prop, Ref } from "vue-property-decorator";

@Component
export default class SmbTextInput extends Vue implements SmbInput {
  @Ref()
  public readonly input!: HTMLInputElement;

  @ModelSync("text", "change", { type: [String, Number], default: "" })
  public value!: string | number;

  @Prop({ type: Boolean, default: false })
  public readonly readonly!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly required!: boolean;

  @Prop({ type: RegExp, default: undefined })
  public readonly regex!: RegExp | undefined;

  @Prop({
    type: String,
    default: "text",
    validator: anyOf("text", "tel", "email", "number", "password", "combo"),
  })
  public readonly type!: string;

  @Prop({ type: String, default: undefined })
  public readonly autocomplete!: string | undefined;

  @Prop({ type: Number, default: undefined })
  public readonly max!: number;

  @Prop({ type: Number, default: undefined })
  public readonly min!: number;

  @Prop({ type: Boolean, default: false })
  public readonly disabled!: boolean;

  @Prop({ type: Array, default: undefined })
  public readonly comboOptions!: { label?: string; value: unknown }[] | undefined;

  @Prop({
    type: Number,
    default: undefined,
    validator: (val: unknown) =>
      typeof val === "undefined" || typeof val === "number",
  })
  public tabindex!: number | undefined;

  public focussed = false;
  public visited = false;
  public triedSubmitting = false;
  public error: string | null = null;
  public errorColor: ComponentColor = "danger";
  public visible = false;

  private instanceId = Math.floor(Math.random() * 1000);

  public get pattern(): string | undefined {
    if (!this.regex) {
      return undefined;
    }

    const validPatternType = [
      "text",
      "tel",
      "email",
      "url",
      "password",
      "search",
    ].includes(this.actualType);

    if (!validPatternType) {
      throw new Error(
        `Inputs of type '${this.actualType}' don't support the pattern attribute. ` +
          "See: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/pattern"
      );
    }

    return this.regex.source;
  }

  public checkInvalid(): void {
    this.triedSubmitting = true;

    this.updateError();
  }

  public get isEmpty(): boolean {
    return this.value === null || String(this.value).length === 0;
  }

  public get isRequiredValid(): boolean {
    return !this.required || !this.isEmpty;
  }

  public updateError(): void {
    const stringText = String(this.value);
    const isString = this.actualType !== "number";

    const regexOk = this.regex ? this.regex.test(stringText) : true;
    const minOk = this.min
      ? isString
        ? stringText.length > this.min
        : (this.value as number) >= this.min
      : true;
    const maxOk = this.max
      ? isString
        ? stringText.length < this.max
        : (this.value as number) <= this.max
      : true;

    if (!this.isRequiredValid) {
      if (!this.triedSubmitting) {
        this.error = null;

        return;
      }

      this.error = "Dieses Feld ist erforderlich";
      this.errorColor = "warning";

      return;
    }

    if (!regexOk) {
      this.error = "Der Wert muss dem geforderten Format entsprechen";
      this.errorColor = "danger";

      return;
    }

    if (!minOk) {
      if (isString) {
        this.error = `Der Wert muss mindestens ${this.min} Zeichen enthalten`;
      } else {
        this.error = `Der Wert muss größer als ${this.min} sein`;
      }
      this.errorColor = "danger";

      return;
    }

    if (!maxOk) {
      if (isString) {
        this.error = `Der Wert darf maximal ${this.max} Zeichen enthalten`;
      } else {
        this.error = `Der Wert muss kleiner als ${this.max} sein`;
      }
      this.errorColor = "danger";

      return;
    }

    this.error = null;
  }

  public get isValid(): boolean {
    return this.error === null;
  }

  public get actualType(): string {
    switch (this.type) {
      case "combo":
        if (!this.comboOptions) {
          throw new Error("Type combo can only be used together with comboOptions.");
        }

        return "text";
      case "password":
        return this.visible ? "text" : this.type;
      default:
        return this.type;
    }
  }

  public focus(): void {
    this.input.focus();
  }

  public onFocusin(e: Event): void {
    this.focussed = true;

    this.$emit("focusin", e);
  }

  public onFocusout(e: Event): void {
    this.visited = true;
    this.focussed = false;

    this.$emit("focusout", e);

    this.updateError();
  }

  public get actualTabIndex(): number | undefined {
    return this.disabled ? -1 : this.tabindex;
  }

  public get stateClass(): string | undefined {
    if ((this.visited || this.triedSubmitting) && !this.isValid) {
      return this.errorColor;
    }

    return undefined;
  }

  public resetError(): void {
    this.triedSubmitting = false;
    this.error = null;
    this.errorColor = "danger";
  }

  public get datalistId(): string {
    return `datalist-${this.instanceId}`;
  }

  public passwordEyeClicked(): void {
    this.visible = !this.visible;

    this.input.focus();
  }
}
