















































import Vue from "vue";
import Component from "vue-class-component";
import DraggableContainer from "@/components/helper/DraggableContainer.vue";
import KeyKeeperSticker from "@/components/helper/KeyKeeperSticker.vue";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import type { KeyKeeper } from "@/api/models/products/KeyKeeper";
import { Prop } from "vue-property-decorator";

@Component({
  components: {KeyKeeperSticker, DraggableContainer, SmbButton}
})
export default class KeyKeeperCards extends Vue {
  @Prop({type: Array, required: true})
  public readonly keyKeepers!: KeyKeeper[];
}
