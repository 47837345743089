





























import Vue from "vue";
import Component from "vue-class-component";
import type { ComponentColor } from "../helpers";
import { colorValidator } from "../helpers";
import { Prop } from "vue-property-decorator";
import type { RawLocation } from "vue-router";

@Component
export default class SmbButton extends Vue {
  @Prop({ type: Boolean, default: false })
  public readonly disabled!: boolean;

  @Prop({ type: String, default: "default", validator: colorValidator("link") })
  public readonly color!: ComponentColor | undefined;

  @Prop({ type: String, default: undefined })
  public readonly to!: RawLocation | undefined;

  @Prop({ type: Boolean, default: undefined })
  public readonly exact!: boolean | undefined;

  @Prop({ type: Boolean, default: undefined })
  public readonly submit!: boolean | undefined;

  @Prop({ type: Boolean, default: undefined })
  public readonly reset!: boolean | undefined;

  @Prop({ type: String, default: undefined })
  public readonly href!: string | undefined;

  @Prop({ type: String, default: undefined })
  public readonly target!: string | undefined;

  public get type(): string | undefined {
    if (this.submit && this.reset) {
      throw new Error("Button cannot be of type submit and reset");
    }

    if (this.buttonComponent !== "button") {
      if (this.submit || this.reset) {
        console.warn(
          "Either 'submit' or 'reset' was set on a non-button component.",
          this
        );
      }

      return undefined;
    }

    if (this.submit) {
      return "submit";
    }

    if (this.reset) {
      return "reset";
    }

    return "button";
  }

  public get classNames(): string[] {
    const classes = ["smb-button"];

    if (this.color) {
      classes.push("theme-" + this.color);
    }

    return classes;
  }

  public get buttonComponent(): string {
    if (this.to) {
      return "router-link";
    }

    if(this.href && !this.target || !this.href && this.target) {
      throw new Error("Both href and target need to be set.");
    }


    if (this.href && this.target) {
      return "a";
    }

    return "button";
  }
}
