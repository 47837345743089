











import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import type { IdTagColor } from "@/api/models/products/IdTagColor";
import type { KeyKeeperColor } from "@/api/models/products/KeyKeeperColor";

@Component
export default class ProductIcon extends Vue {
  @Prop({ type: [String, Array], required: true })
  public readonly icon!: string | string[];

  @Prop({ type: String, default: undefined })
  public readonly color!: IdTagColor | KeyKeeperColor | undefined;

  @Prop({ type: String, default: undefined })
  public readonly size!: string | undefined;
}
