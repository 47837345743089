






























import Vue from "vue";
import Component from "vue-class-component";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import {Prop} from "vue-property-decorator";
import type { ComponentColor} from "@/components/ui/helpers";
import {anyOf, colorValidator} from "@/components/ui/helpers";

@Component({
  components: {SmbButton},
})
export default class SmbIconButton extends Vue {
  @Prop({type: String, default: undefined})
  public readonly icon!: string | undefined;

  @Prop({type: String, default: undefined})
  public readonly size!: string | undefined;

  @Prop({type: String, default: "default", validator: colorValidator("link")})
  public readonly color!: ComponentColor;

  @Prop({type: String, default: "default", validator: colorValidator()})
  public readonly iconColor!: ComponentColor;

  @Prop({type: String, default: "top", validator: anyOf("top", "bottom", "left", "right")})
  public readonly position!: "top" | "bottom" | "left" | "right";
}
