




















































import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import type {KeyKeeper} from "@/api/models/products/KeyKeeper";
import KeyKeeperSticker from "@/components/helper/KeyKeeperSticker.vue";

@Component({
  components: {KeyKeeperSticker, SmbTextInput},
  metaInfo(this: ViewKeyKeeper) {
    const keeper = this.keeper;

    let title = "KeyKeeper";
    if (typeof keeper !== "undefined" && keeper !== null && keeper.description.length > 0) {
      title = keeper.description;
    }

    return { title };
  },
})
export default class ViewKeyKeeper extends Vue {
  @Prop({type: Object, default: null})
  public readonly keeper!: KeyKeeper | null;
}
