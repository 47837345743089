































































































































import AuthLayout from "@/components/layout/AuthLayout.vue";
import Vue from "vue";
import Component from "vue-class-component";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import SmbCodeInput from "@/components/ui/SmbCodeInput/SmbCodeInput.vue";
import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import type { ApiMessage } from "@/api/models/common/ApiResponse";
import { fail, fromResponse } from "@/api/helper";
import { PasswordRegex } from "@/components/ui/helpers";
import PasswordChecklist from "@/components/helper/PasswordChecklist.vue";
import type { NewPasswordForm } from "@/api/models/auth/NewPasswordForm";
import type { ForgotPasswordPostForm } from "@/api/models/auth/ForgotPasswordPostForm";
import { Ref, Watch } from "vue-property-decorator";
import ApiClient from "@/api/client/ApiClient";

@Component({
  components: {PasswordChecklist, SmbButton, SmbTextInput, SmbApiAlert, SmbCodeInput, AuthLayout},
})
export default class RecoverPassword extends Vue {
  @Ref()
  public readonly passwordField!: SmbTextInput | undefined;

  @Ref()
  public readonly passwordConfirmField!: SmbTextInput | undefined;

  public readonly codeLength = 8;
  public readonly passwordRegex = PasswordRegex;

  public processing = false;
  public errorMessage: ApiMessage | null = null;
  public forgotPasswordForm: ForgotPasswordPostForm = {
    mail: "",
  };
  public newPasswordForm: NewPasswordForm = {
    password: "",
    code: "",
    mail: "",
  };
  public passwordConfirm = "";
  public mailFixed = false;
  public codeSent = false;
  public passwordFieldFocussed = false;
  public passwordConfirmFieldFocussed = false;
  public showPasswordHint = false;

  public get codeFilled(): boolean {
    return this.newPasswordForm.code.length === this.codeLength && !this.newPasswordForm.code.includes(" ");
  }

  @Watch("passwordFieldFocussed")
  public onPasswordFieldFocussed(val: boolean): void {
    setTimeout(() => this.showPasswordHint = val || this.passwordConfirmFieldFocussed);
  }

  @Watch("passwordConfirmFieldFocussed")
  public onPasswordConfirmFieldFocussed(val: boolean): void {
    setTimeout(() => this.showPasswordHint = val || this.passwordFieldFocussed);
  }

  public async submitForgotPassword(): Promise<void> {
    try {
      this.processing = true;

      let response = await ApiClient.postJson("Authentication/ForgotPassword", this.forgotPasswordForm);

      this.errorMessage = fromResponse(response);

      if (response.success) {
        this.mailFixed = true;
        this.codeSent = true;
      }
    } catch (e: unknown) {
      this.errorMessage = fail((e as Error).message);
    } finally {
      this.processing = false;
    }
  }

  public async submitNewPassword(): Promise<void> {
    try {
      this.processing = true;

      this.newPasswordForm.mail = this.forgotPasswordForm.mail;

      let response = await ApiClient.postJson("Authentication/NewPassword", this.newPasswordForm);
      if (!response.success) {
        this.errorMessage = fromResponse(response);

        return;
      }

      await this.$router.push("/login");
      this.$toast.success(response.message);
    } catch (e: unknown) {
      this.errorMessage = fail((e as Error).message);
    } finally {
      this.processing = false;
    }
  }
}
