



















































































import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import { useAuthStore } from "@/store/auth";
import Vue from "vue";
import Component from "vue-class-component";
import { useBusyStore } from "@/store/busy";
import SmbSelect from "@/components/ui/SmbSelect/SmbSelect.vue";

@Component({
  components: { SmbSelect, SmbTextInput },
})
export default class Debug extends Vue {
  public test = "";
  public textRegex = "^\\d*$";
  public textRequired = false;
  public textDisabled = false;
  public textReadonly = false;
  public selectVal = "";

  public formSubmitted(): void {
    alert("submitted");
  }

  public get textRegexPattern(): RegExp | undefined {
    try {
      return this.textRegex.length > 0 ? new RegExp(this.textRegex) : undefined;
    } catch (e: unknown) {
      return undefined;
    }
  }

  public get textInputValid(): boolean | undefined {
    // eslint-disable-next-line
    this.test; // required so getter value is updated once text value changes

    return (this.$refs.textInput as SmbTextInput)?.isValid;
  }

  public get textInputVisited(): boolean | undefined {
    // eslint-disable-next-line
    this.test; // required so getter value is updated once text value changes

    return (this.$refs.textInput as SmbTextInput)?.visited;
  }

  public get textInputTriedSubmitting(): boolean | undefined {
    // eslint-disable-next-line
    this.test; // required so getter value is updated once text value changes

    return (this.$refs.textInput as SmbTextInput)?.triedSubmitting;
  }

  public globalLoader(): void {
    const busy = useBusyStore();

    busy.setGlobal(true, "Debugging");
  }

  public viewportLoader(): void {
    const busy = useBusyStore();

    busy.setViewport(true, "Debugging");
  }

  public async getUser(): Promise<void> {
    const auth = useAuthStore();
    const user = await auth.tryGetUser();

    if (user) {
      this.$toast.success(`Ok: ${user.mail}`);
    } else {
      this.$toast.warning("Unauthenticated");
    }
  }

  public async getUserRaw(): Promise<void> {
    const auth = useAuthStore();
    const user = await auth.tryGetUser(false);

    if (user) {
      this.$toast.success(`Ok: ${user.mail}`);
    } else {
      this.$toast.warning("Unauthenticated");
    }
  }
}
