








import Vue from "vue";
import Component from "vue-class-component";

@Component({
  metaInfo: {
    titleTemplate: "%s | sendmeback Admin"
  }
})
export default class AdminLayout extends Vue {
}
