































































import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import type {ApiMessage} from "@/api/models/common/ApiResponse";
import type {NavigationGuardNext, Route} from "vue-router";
import {useProductsStore} from "@/store/products";
import loadBeforeEnter from "@/components/helper/loadBeforeEnter";
import type {ToolbarItem} from "@/components/ui/SmbToolbar/SmbToolbar.vue";
import SmbToolbar from "@/components/ui/SmbToolbar/SmbToolbar.vue";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import type {KeyKeeper} from "@/api/models/products/KeyKeeper";
import SmbIconButton from "@/components/ui/SmbIconButton/SmbIconButton.vue";
import KeyKeeperColorSelect from "@/components/helper/KeyKeeperColorSelect.vue";
import {mapActions} from "pinia";
import {useBusyStore} from "@/store/busy";
import type {ComponentColor} from "@/components/ui/helpers";
import SmbDropdown from "@/components/ui/SmbDropdown/SmbDropdown.vue";
import SmbDropdownItem from "@/components/ui/SmbDropdown/SmbDropdownItem.vue";

@Component({
  components: {
    SmbDropdownItem,
    SmbDropdown,
    KeyKeeperColorSelect,
    SmbIconButton,
    SmbTextInput,
    SmbApiAlert,
    SmbButton,
    SmbToolbar
  },
  methods: {
    ...mapActions(useBusyStore, ["setViewport"]),
  },
})
export default class KeyKeeperLayout extends Vue {
  @Prop({type: String, default: null})
  public readonly id!: string | null;

  public readonly setViewport!: (busy: boolean, message?: string, color?: ComponentColor) => void;

  public message: null | ApiMessage = null;
  public keeper: null | KeyKeeper = null;
  public actionsMenuOpen = false;

  public get isNewKeeper(): boolean {
    return this.id === null;
  }

  public async beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<this>): Promise<void> {
    if (to.name === "add-key-keeper") {
      next((vm) => {
        vm.keeper = null;
      });

      return;
    }

    const products = useProductsStore();
    await loadBeforeEnter(
      next,
      products.getKeyKeeperById(Number(to.params.id)),
      (vm, keyKeeper, error) => {
        vm.message = error;
        vm.keeper = keyKeeper;
      },
    );
  }

  public get toolbarItems(): ToolbarItem[] {
    return [
      {
        text: "Übersicht",
        icon: "eye",
        click: async (): Promise<void> => {
          if (this.$route.name !== "view-key-keeper")
            await this.$router.push(`/products/key-keeper/${this.keeper?.id}`);
        },
      },
      {
        text: "Verlust",
        icon: "lock",
        click: async (): Promise<void> => {
          if (this.$route.name !== "locate-key-keeper")
            await this.$router.push(`/products/key-keeper/${this.keeper?.id}/reportlost`);
        },
      },
      // {
      //   text: "Übertragen",
      //   icon: "share",
      //   click: (): void => {
      //     alert("Übertragen");
      //   },
      // },
      {
        text: "Löschen",
        icon: "trash",
        click: async (): Promise<void> => {
          if (this.$route.name !== "delete-key-keeper")
            await this.$router.push(`/products/key-keeper/${this.keeper?.id}/delete`);
        },
      },
      {
        text: "Bearbeiten",
        icon: "pen",
        click: async (): Promise<void> => {
          if (this.$route.name !== "edit-key-keeper")
            await this.$router.push(`/products/key-keeper/${this.keeper?.id}/edit`);
        },
      },
    ];
  }
}
