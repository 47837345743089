


























































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import type { IdTag } from "@/api/models/products/IdTag";
import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import IdTagSticker from "@/components/helper/IdTagSticker.vue";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import ApiClient from "@/api/client/ApiClient";
import { fromResponse, handleApiError } from "@/api/helper";
import type { ApiMessage } from "@/api/models/common/ApiResponse";
import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import SmbAlert from "@/components/ui/SmbAlert/SmbAlert.vue";

@Component({
  components: {SmbAlert, SmbApiAlert, SmbButton, IdTagSticker, SmbTextInput },
  metaInfo(this: ReportLostIdTag) {
    const idTag = this.tag;

    return {
      title: idTag?.description ?? "ID-TAG",
    };
  },
})
export default class ReportLostIdTag extends Vue {
  @Prop({ type: Object, default: null })
  public readonly tag!: IdTag | null;

  public message: ApiMessage | null = null;

  public async doReportLost(): Promise<void> {
    try {
        const result = await ApiClient.postJson("Device/ReportLost?deviceId=" + this.tag?.id);

      if (result.success)
        await this.$router.push("/products?msg=report_lost");
      else this.message = fromResponse(result);
    } catch (e: unknown) {
      this.message = await handleApiError(e as Error);
    }
  }

  public async doReportFoundOwn(): Promise<void> {
    try {
      const result = await ApiClient.postJson("Device/ReportFoundOwn?deviceId=" + this.tag?.id);

      if (result.success)
        await this.$router.push("/products?msg=report_found_own");
      else this.message = fromResponse(result);
    } catch (e: unknown) {
      this.message = await handleApiError(e as Error);
    }
  }
}
