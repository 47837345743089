import 'leaflet/dist/leaflet.css';
import {Icon} from "leaflet";
import {LMap, LTileLayer, LMarker} from "vue2-leaflet";
import Vue from "vue";
import marker2xIcon from "leaflet/dist/images/marker-icon-2x.png";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

type D = Icon.Default & {
  _getIconUrl?: string;
};

delete (Icon.Default.prototype as D)._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: marker2xIcon,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

Vue.component('LMap', LMap);
Vue.component('LTileLayer', LTileLayer);
Vue.component('LMarker', LMarker);
