

















































































import Vue from "vue";
import Component from "vue-class-component";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import ApiClient from "@/api/client/ApiClient";
import { handleApiError } from "@/api/helper";
import type { CheckSerialNumberResponse } from "@/api/models/devices/CheckSerialNumberResponse";
import type { CheckSerialNumberPostForm } from "@/api/models/devices/CheckSerialNumberPostForm";

@Component({
  components: { SmbButton },
  metaInfo: {
    title: "BE.PROTECT",
  },
})
export default class BEPROTECT extends Vue {
  public serialNumber = "";
  public resultClass = "smb-no-result";
  public resultText = "";
  public infoText = "Keine Seriennummer geprüft";
  public deviceText = "";

  public async checkSerialNumber(): Promise<void> {
    if (!this.serialNumber) {
      this.resultClass = "smb-no-result";
      this.resultText = "";
      this.infoText = "Keine Seriennummer eingegeben";
      this.deviceText = "";
    } else {
      try {
        const checkSerialNumberResponse: CheckSerialNumberResponse =
          await ApiClient.postJson("device/CheckSerialNumber", {
            serialNumber: this.serialNumber,
          } as CheckSerialNumberPostForm);

        this.setCheckSerialNumberResult(checkSerialNumberResponse);
      } catch (e: unknown) {
        await handleApiError(e as Error);
      }
    }
  }

  private setCheckSerialNumberResult(
    checkSerialNumberResponse: CheckSerialNumberResponse
  ): void {
    switch (checkSerialNumberResponse.state) {
      case "not_stolen":
        this.resultClass = "smb-result-good";
        this.resultText = "GLÜCKWUNSCH!";
        this.infoText = "Gegenstand wurde nicht als Verloren gemeldet.";
        this.deviceText = `Info: ${checkSerialNumberResponse?.modelName} (${checkSerialNumberResponse?.manufacturerName})`;
        break;
      case "stolen":
        this.resultClass = "smb-result-bad";
        this.resultText = "ACHTUNG!";
        this.infoText = "Gegenstand wurde als Verloren gemeldet.";
        this.deviceText = `Info: ${checkSerialNumberResponse?.modelName} (${checkSerialNumberResponse?.manufacturerName})`;
        break;
      case "unknown":
        this.resultClass = "smb-result-middle";
        this.resultText = "INFO!";
        this.infoText = "Gegenstand ist im System nicht hinterlegt.";
        this.deviceText = "";
        break;
    }
  }
}
