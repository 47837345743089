


































import Vue from "vue";
import Component from "vue-class-component";
import {Model, Prop} from "vue-property-decorator";

@Component
export default class SmbCodeInput extends Vue {
  @Model('input')
  public readonly value!: string;

  @Prop({ type: Number, required: true })
  public readonly numDigits!: number;

  @Prop({ type: Boolean, default: false })
  public readonly readonly!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly required!: boolean;

  @Prop({ type: Number, default: undefined })
  public readonly tabindex!: number | undefined;

  @Prop({ type: Boolean, default: false })
  public readonly disabled!: boolean;

  public code = "";

  public get actualTabIndex(): number | undefined {
    return this.disabled ? -1 : this.tabindex;
  }

  public get digitIndices(): number[] {
    // generate a list of number matching their index
    return Array(this.numDigits)
      .fill(0)
      .map((_, i) => i);
  }

  public get digits(): string[] {
    return this.code.split("");
  }

  public set digits(val: string[]) {
    this.code = val.join("");
  }

  public updateDigit(idx: number, val: string): void {
    const digits = this.digits;
    digits[idx] = val;
    this.digits = digits;

    this.$emit("input", this.code);
  }

  public focusDigit(idx: number): void {
    const inputs = this.$refs.input as [HTMLInputElement];
    const inputRef = inputs[idx];

    inputRef.focus();
  }

  public handlePaste(event: ClipboardEvent): void {
    const text = event.clipboardData?.getData("text");
    const pattern = new RegExp(`^\\d{${this.numDigits},}`);
    if (!text || !pattern.test(text)) {
      return;
    }

    this.code = text.substr(0, this.numDigits);
    this.$emit("input", this.code);

    this.focusDigit(this.numDigits - 1);

    this.$emit("submit");
  }

  public handleKeyDown(idx: number, event: KeyboardEvent): void {
    switch (event.key) {
      case "v":
        // do nothing for paste as it is handled above
        break;
      case "Enter":
        this.$emit("submit");
        break;
      case "Backspace":
        this.updateDigit(idx, " ");

        if (idx > 0) {
          this.focusDigit(idx - 1);
        }

        event.preventDefault();
        break;
      case "ArrowLeft":
        if (idx > 0) {
          this.focusDigit(idx - 1);
        }
        break;
      case "ArrowRight":
        if (idx < this.numDigits - 1) {
          this.focusDigit(idx + 1);
        }
        break;
      case "Tab":
        if (event.shiftKey) {
          if (idx > 0) {
            this.focusDigit(idx);
          }
        } else {
          if (idx < this.numDigits - 1) {
            this.focusDigit(idx);
          }
        }
        break;
      default:
        if (/^\d$/.test(event.key)) {
          this.updateDigit(idx, event.key);

          if (idx < this.numDigits - 1) {
            this.focusDigit(idx + 1);
          } else {
            this.$emit("submit");
          }

          event.preventDefault();
        }

        break;
    }
  }
}
