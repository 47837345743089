




























import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Model } from "vue-property-decorator";

@Component
export default class SmbSwitch extends Vue {
  @Model("change", { type: Boolean, default: false })
  public enabled!: boolean;

  @Emit("change")
  public toggle(): boolean {
    return !this.enabled;
  }
}
