













































































































import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import type {IdTag} from "@/api/models/products/IdTag";
import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import IdTagSticker from "@/components/helper/IdTagSticker.vue";

@Component({
  components: {
    IdTagSticker,
    SmbTextInput
  },
  metaInfo(this: ViewIdTag) {
    const idTag = this.tag;

    return {
      title: idTag?.description ?? "ID-TAG",
    };
  },
})
export default class ViewIdTag extends Vue {
  @Prop({
    type: Object,
    default: null
  })
  public readonly tag!: IdTag | null;

}
