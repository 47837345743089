









































































import Vue from "vue";
import Component from "vue-class-component";
import { KeyKeeperColor } from "@/api/models/products/KeyKeeperColor";
import { Model, Prop } from "vue-property-decorator";

@Component
export default class KeyKeeperColorSelect extends Vue {
  @Model("change", {type: String, default: KeyKeeperColor.Black})
  public readonly model!: KeyKeeperColor;

  @Prop({ type: Boolean, default: false })
  public readonly disabled!: boolean;

  public readonly KeyKeeperColor = KeyKeeperColor;

  public changeTo(color: KeyKeeperColor): void {
    if (this.disabled)
      return;

    this.$emit('change', color);
  }
}
