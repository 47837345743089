import type {MenuEntry} from "@/api/models/menu/MenuEntry";
import {UserRole} from "@/api/models/auth/UserRole";
import {defineStore} from "pinia";

export const useLayoutStore = defineStore({
  id: "layout",

  state() {
    return {
      title: null as string | null,
      menus: {
        head: [] as MenuEntry[],
        side: {
          top: [] as MenuEntry[],
          bottom: [] as MenuEntry[],
        }
      },
      devicesView: 'cards',
    };
  },

  actions: {
    resetMenus(): void {
      this.menus.head = [];
      this.menus.side.top = [];
      this.menus.side.bottom = [];
      this.setMenusByRole(UserRole.Guest);
    },

    setMenusByRole(role: UserRole): void {
      this.setHeadMenuByRole(role);
      this.setSideMenuByRole(role);
    },

    setSideMenuByRole(role: UserRole): void {
      this.setSideTopMenuByRole(role);
      this.setSideBottomMenuByRole(role);
    },

    setSideTopMenuByRole(role: UserRole): void {
      const authenticated = role !== UserRole.Guest;
      if (authenticated) {
        const common: MenuEntry[] = [
          {
            order: 0,
            external: false,
            text: "Aktuelles",
            link: "/blog",
            icon: "newspaper",
            isDivider: false,
          },
          {
            order: 1,
            external: false,
            text: "Geräteübersicht",
            link: "/products",
            icon: "list",
            isDivider: false,
          },
          {
            order: 2,
            external: false,
            text: "BE.ON",
            link: "/be-on",
            icon: "mobile-alt",
            isDivider: false,
          },
          {
            order: 3,
            external: false,
            text: "BE.IN",
            link: "/be-in",
            icon: "key",
            isDivider: false,
          },
          {
            order: 4,
            external: false,
            text: "BE.PROTECT",
            link: "/be-protect",
            icon: "check-circle",
            isDivider: false,
          },
        ];

        let roleSpecific: MenuEntry[] = [];
        if (role === UserRole.Admin) {
          roleSpecific = [
            {
              order: 9,
              isDivider: true,
            },
            // {
            //   order: 10,
            //   external: false,
            //   text: "Admin Dashboard",
            //   link: "/admin/dashboard",
            //   icon: "key",
            //   isDivider: false,
            // },
            {
              order: 12,
              external: false,
              text: "Kundenübersicht",
              link: "/admin/customers",
              icon: "users",
              isDivider: false,
            },
            {
              order: 13,
              external: false,
              text: "Geräteübersicht",
              link: "/admin/devices",
              icon: "mobile-alt",
              isDivider: false,
            },
            {
              order: 14,
              external: false,
              text: "Bestellübersicht",
              link: "/admin/orders",
              icon: "shopping-cart",
              isDivider: false,
            },
            // {
            //   order: 15,
            //   external: false,
            //   text: "Statusbearbeitung",
            //   link: "/admin/state-management",
            //   icon: "edit",
            //   isDivider: false,
            // },
            // {
            //   order: 16,
            //   external: false,
            //   text: "Statistiken",
            //   link: "/admin/statistics",
            //   icon: "chart-line",
            //   isDivider: false,
            // },
            // {
            //   order: 99,
            //   external: false,
            //   text: "Debug",
            //   link: "/admin/debug",
            //   icon: "bug",
            //   isDivider: false,
            // },
          ];
        }

        this.menus.side.top = common.concat(roleSpecific);
      } else {
        this.menus.side.top = [
          {
            order: 0,
            external: false,
            text: "Login",
            link: "/login",
            icon: "key",
            isDivider: false,
          },
        ];
      }
    },

    setSideBottomMenuByRole(role: UserRole): void {
      const common: MenuEntry[] = [
        {
          order: 0,
          external: false,
          text: "Fund melden",
          link: "/report-find",
          icon: "square",
          color: "success",
          isDivider: false,
        }
      ];

      let roleSpecific: MenuEntry[] = [];
      switch (role) {
        case UserRole.Admin:
          roleSpecific = [];
          break;
        // case UserRole.Customer:
        //   roleSpecific = [
        //     { order: 1, external: false, text: "Verlust melden", link: "/report-loss", icon: "square", color: "danger" },
        //   ];
        //   break;
      }

      this.menus.side.bottom = common.concat(roleSpecific);
    },

    setHeadMenuByRole(role: UserRole): void {
      const common: MenuEntry[] = [
        {
          order: 0,
          external: false,
          text: "Profil",
          link: "/profile",
          isDivider: false,
        },
        {
          order: 1,
          external: false,
          text: "Account",
          link: "/account",
          isDivider: false,
        },
        {
          order: 2,
          external: false,
          text: "Einstellungen",
          link: "/settings",
          isDivider: false,
        },
      ];

      let roleSpecific: MenuEntry[] = [];
      switch (role) {
        case UserRole.Admin:
          roleSpecific = [
            {
              order: 3,
              external: false,
              text: "Rechteverwaltung",
              link: "/admin/permissions",
              isDivider: false,
            }
          ];
          break;
        case UserRole.Customer:
          roleSpecific = [
            {
              order: 3,
              external: false,
              text: "Support",
              link: "/support",
              isDivider: false,
            },
            {
              order: 4,
              external: false,
              text: "Rechtliche Hinweise",
              link: "/legal",
              isDivider: false,
            },
          ];
          break;
      }

      this.menus.head = common.concat(roleSpecific);
    },
  },
});
