




















import Vue from "vue";
import Component from "vue-class-component";
import { IdTagColor } from "@/api/models/products/IdTagColor";
import { Prop } from "vue-property-decorator";

import blackTag from "@/assets/smb-id-tag-black.256.png";
import whiteTag from "@/assets/smb-id-tag-white.256.png";
import silverTag from "@/assets/smb-id-tag-silver.256.png";

@Component
export default class IdTagSticker extends Vue {
  @Prop({ type: String, default: undefined })
  public readonly code!: string | undefined;

  @Prop({ type: String, default: undefined })
  public readonly color!: IdTagColor | undefined;

  @Prop({type: [String, Number], default: 1})
  public readonly scale!: string | number;

  public get imageUrl(): string | undefined {
    if (typeof this.color === "undefined")
      return undefined;

    switch (this.color) {
      case IdTagColor.Black:
        return blackTag;
      case IdTagColor.White:
        return whiteTag;
      case IdTagColor.Silver:
        return silverTag;
      default:
        throw new Error(`IdTag color ${this.color} is not implemented.`);
    }
  }
}
