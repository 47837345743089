









import Vue from "vue";
import Component from "vue-class-component";
import { Model, Prop } from "vue-property-decorator";
import type { ToolbarItem } from "@/components/ui/SmbToolbar/SmbToolbar.vue";
import SmbToolbar from "@/components/ui/SmbToolbar/SmbToolbar.vue";
import type { ComponentColor } from "@/components/ui/helpers";

export interface ToggleItem {
  text?: string;
  icon?: string;
  color?: ComponentColor;
  iconColor?: ComponentColor;
  iconSize?: string;
  activeColor?: ComponentColor;
  activeIconColor?: ComponentColor;
}

@Component({
  components: {SmbToolbar},
})
export default class SmbToggleButton extends Vue {
  @Model("changed", { type: String, required: true })
  public readonly selected!: string;

  @Prop({ type: Object, required: true })
  public readonly options!: { [key: string]: ToggleItem };

  @Prop({ type: Boolean, default: false })
  public readonly inline!: boolean;

  @Prop({ type: String, default: "default" })
  public readonly color!: ComponentColor;

  @Prop({ type: String, default: "white" })
  public readonly iconColor!: ComponentColor;

  @Prop({ type: String, default: "primary" })
  public readonly activeColor!: ComponentColor;

  @Prop({ type: String, default: "white" })
  public readonly activeIconColor!: ComponentColor;

  public get optionKeys(): string[] {
    return Object.keys(this.options);
  }

  public get toolbarOptions(): ToolbarItem[] {
    return this.optionKeys.map(o => {
      const isSelected = this.selected === o;
      const opt = this.options[o];

      return {
        text: opt.text,
        icon: opt.icon,
        click: (): void => { this.$emit('changed', o); },
        color: isSelected ? (opt.activeColor ?? this.activeColor) : opt.color,
        iconColor: isSelected ? (opt.activeIconColor ?? this.activeIconColor) : opt.iconColor,
        iconSize: opt.iconSize,
      };
    });
  }
}
