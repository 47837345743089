



















import type { BusyState } from "@/store/helpers/BusyState";
import Component from "vue-class-component";
import Vue from "vue";
import { watch } from "@vue/composition-api";
import SmbLoader from "@/components/ui/SmbLoader/SmbLoader.vue";
import { pinia } from "@/plugins/pinia";
import { useBusyStore } from "@/store/busy";
import {useSettingsStore} from "@/store/settings";

@Component({
  components: { SmbLoader },
  metaInfo: {
    title: "Willkommen",
    titleTemplate: "%s | sendmeback Account",
  }
})
export default class App extends Vue {
  public globalLoader: BusyState = { active: false, message: undefined };

  public async created(): Promise<void> {
    // need to use this "hack" since the store cannot be mapped before the App is ready (and mapping would happen before
    // that)
    useBusyStore();
    watch(
      pinia.state.value.busy,
      (state) => {
        this.globalLoader = state.global;
      },
      { deep: true },
    );

    const settings = useSettingsStore();
    settings.loadState();
    settings.$subscribe(() => { settings.saveState(); });
  }
}
