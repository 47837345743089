// import error reporter first to capture EVERYTHING (depends on ApiClient)
import "@/plugins/apiClient";
import "@/plugins/errorReporter";

// now import all plugins (should self register with vue)
import "@/plugins/compositionApi";
import "@/plugins/fontAwesome";
import "@/plugins/leaflet";
import "@/plugins/vueMeta";
import "@/plugins/vueToast";
import { pinia } from "@/plugins/pinia";

// begin of application
import "@/scss/app.scss";
import Vue from "vue";
import App from "@/App.vue";
import type { VNode } from "vue/types/umd";
import router from "@/router";

// global component mixins
import "@/mixins/TitleFromMeta"

// global component filters
import "@/filters/dayJs"
import "@/filters/yesNo"

// globally available components
import ProductIcon from "@/components/helper/ProductIcon.vue";
Vue.component('ProductIcon', ProductIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  pinia,
  render: (h):VNode => h(App),
}).$mount("#app");
