import { defineStore } from "pinia";

const localSettingsKey = "user_settings";

export interface MessageSettings {
  welcome: boolean;
  appInfo: boolean;
}

export interface SettingsState {
  messages: MessageSettings;
}

export const useSettingsStore = defineStore({
  id: "settings",

  state() {
    const state: SettingsState = {
      messages: {
        welcome: false,
        appInfo: true,
      },
    };

    return state;
  },

  actions: {
    loadState(): void {
      const encoded = localStorage.getItem(localSettingsKey);
      if (!encoded) {
        return;
      }

      const storedState = JSON.parse(atob(encoded));
      if (!storedState) {
        return;
      }

      this.$patch(storedState);
    },

    saveState(): void {
      const encoded = btoa(JSON.stringify(this.$state));

      localStorage.setItem(localSettingsKey, encoded);
    }
  }
});
