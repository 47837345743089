import { ApiError } from "./ApiError";
import type { ApiResponse } from "../models/common/ApiResponse";
import { UnauthenticatedError } from "@/api/client/UnauthenticatedError";
import { useAuthStore } from "@/store/auth";
import {TokenBucketRateLimiter} from "@/api/client/TokenBucketRateLimiter";

export type RequestBodyKey = string;
// eslint-disable-next-line
export type RequestBodyValue = any;
export type RequestBody = Record<RequestBodyKey, RequestBodyValue>;

export default class ApiClient {
  private static baseUrl?: string = undefined;
  private static tokenBucket: TokenBucketRateLimiter = new TokenBucketRateLimiter();
  private static enableLog = process.env.NODE_ENV === "development" || process.env.VUE_APP_DEBUG_LOG === "true";

  public static setBaseUrl(url: string): void {
    this.baseUrl = url;
  }

  public static setRateLimits(maxRequests: number, rateLimitWindowMS: number): void {
    this.tokenBucket.maxTokens = maxRequests;
    this.tokenBucket.tokenResetTimeoutMS = rateLimitWindowMS;
  }

  public static async get(
    url: string,
    implicitRefresh = true,
  ): Promise<Response> {
    const requestInit: RequestInit = {
      headers: this.buildHeaders(),
      credentials: "include",
    };

    return await this.refreshingFetch(url, requestInit, implicitRefresh);
  }

  public static async post(
    url: string,
    requestBody?: RequestBody,
    implicitRefresh = true,
  ): Promise<Response> {
    const requestInit: RequestInit = {
      method: "POST",
      body: requestBody ? this.convertToFormData(requestBody) : undefined,
      headers: this.buildHeaders(),
      credentials: "include",
    };

    return await this.refreshingFetch(url, requestInit, implicitRefresh);
  }

  public static async delete(
    url: string,
    requestBody?: RequestBody,
    implicitRefresh = true,
  ): Promise<Response> {
    const requestInit: RequestInit = {
      method: "DELETE",
      body: requestBody ? this.convertToFormData(requestBody) : undefined,
      headers: this.buildHeaders(),
      credentials: "include",
    };

    return await this.refreshingFetch(url, requestInit, implicitRefresh);
  }

  public static async getJson<Response = ApiResponse>(
    url: string,
    implicitRefresh = true,
  ): Promise<Response> {
    const response = await this.get(url, implicitRefresh);

    try {
      return await response.json();
    } catch (e: unknown) {
      throw new ApiError(response, "Failed to parse result as JSON");
    }
  }

  public static async postJson<Response = ApiResponse>(
    url: string,
    requestBody?: RequestBody,
    implicitRefresh = true,
  ): Promise<Response> {
    const response = await this.post(url, requestBody, implicitRefresh);

    try {
      return await response.json();
    } catch (e: unknown) {
      throw new ApiError(response, "Failed to parse result as JSON");
    }
  }

  public static async deleteJson<Response = ApiResponse>(
    url: string,
    requestBody?: RequestBody,
    implicitRefresh = true,
  ): Promise<Response> {
    const response = await this.delete(url, requestBody, implicitRefresh);

    try {
      return await response.json();
    } catch (e: unknown) {
      throw new ApiError(response, "Failed to parse result as JSON");
    }
  }

  private static log(...data: unknown[]): void {
    if (!ApiClient.enableLog)
      return;

    console.debug("[ApiClient]", ...data);
  }

  private static async refreshingFetch(
    url: string,
    requestInit: RequestInit,
    implicitRefresh: boolean,
  ): Promise<Response> {
    const method = requestInit.method ?? "GET";
    const fullUrl: string = this.getFullUrl(url);

    ApiClient.log(`[${method}] ${url}`);
    let response = await this.tokenLimitedFetch(fullUrl, requestInit);

    if (!response.ok) {
      if (response.status === 429) { // "too many requests"
        const retryAfter = response.headers.get('retry-after') ?? `${this.tokenBucket.tokenResetTimeoutMS / 1000}`;
        const millisToSleep = this.getMillisToSleep(retryAfter);
        ApiClient.log("Too many requests. Sleeping for", millisToSleep, "ms");

        await new Promise(r => setTimeout(r, millisToSleep));
      } else if (response.status === 401) { // "unauthenticated"
        if (!implicitRefresh) {
          throw new UnauthenticatedError(response);
        }

        ApiClient.log("Executing implicit refresh.");

        const auth = useAuthStore();
        const isTokenRefreshed = await auth.tryRefreshToken();
        if (!isTokenRefreshed) {
          throw new UnauthenticatedError(response);
        }
      } else {
        ApiClient.log(`General error (status ${response.status}). Retrying...`);

        await new Promise(r => setTimeout(r, 500));
      }

      ApiClient.log(`[${method}] ${url} (second attempt)`);
      response = await this.tokenLimitedFetch(fullUrl, requestInit);
    }

    if (!response.ok) {
      if (response.status === 401) {
        throw new UnauthenticatedError(response);
      }

      throw new ApiError(response, `Response status does not indicate success: ${response.status}`);
    }

    return response;
  }

  private static getMillisToSleep(retryHeaderString: string): number {
    let millisToSleep = Math.round(parseFloat(retryHeaderString) * 1000)
    if (isNaN(millisToSleep)) {
      millisToSleep = Math.max(0, new Date(retryHeaderString).valueOf() - new Date().valueOf());
    }

    return millisToSleep
  }

  private static async tokenLimitedFetch(
    url: string,
    requestInit: RequestInit
  ): Promise<Response>
  {
    return await this.tokenBucket.acquireToken(async () => await fetch(url, requestInit));
  }

  private static buildHeaders(): HeadersInit {
    // MIND: currently, this method does not do anything. It is however possible we need to set specific headers later
    // on. This is why it is still incorporated into every API request.
    return {};
  }

  private static convertToFormData(requestBody: RequestBody): FormData {
    const formData = new FormData();
    const keys = Object.keys(requestBody);

    for (const key of keys) {
      if (requestBody[key] instanceof File) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        formData.append(`files.${key}`, requestBody[key]);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        formData.append(key, requestBody[key]);
      }
    }

    return formData;
  }

  private static getFullUrl(url: string): string {
    let fullUrl: URL;

    if (this.baseUrl !== undefined) {
      fullUrl = new URL(this.baseUrl + url);
    } else {
      fullUrl = new URL(url);
    }

    return fullUrl.href;
  }
}
