

































import DashboardHead from "@/components/layout/DashboardHead.vue";
import DashboardMenu from "@/components/layout/DashboardMenu.vue";
import SmbLoader from "@/components/ui/SmbLoader/SmbLoader.vue";
import { useBusyStore } from "@/store/busy";
import type { BusyState } from "@/store/helpers/BusyState";
import { mapState } from "pinia";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: { DashboardHead, DashboardMenu, SmbLoader },
  computed: {
    ...mapState(useBusyStore, { viewportBusy: "viewport" }),
  },
})
export default class DashboardLayout extends Vue {
  public readonly viewportBusy!: BusyState;
}
