
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class SmbDropdownItem extends SmbButton {
  @Prop({ type: Boolean, default: false })
  public readonly divider!: boolean;

  public get classNames(): string[] {
    let val = ["dropdown-item"];

    if (this.divider) {
      val.push("divider");
    }

    return val;
  }

  public get buttonComponent(): string {
    return this.divider ? "div" : (this.to ? "router-link" : "button");
  }
}
