import {fromResponse, handleApiError, success} from "@/api/helper";

import ApiClient from "@/api/client/ApiClient";
import type {ApiResponse} from "@/api/models/common/ApiResponse";
import {Gender} from "@/api/models/auth/Gender";
import type {GetUserProfileResponse} from "@/api/models/profile/GetUserProfileResponse";
import type {ProfileImagePostForm} from "@/api/models/profile/ProfileImagePostForm";
import type {ProfilePostForm} from "@/api/models/profile/ProfilePostForm";
import {defineStore} from "pinia";

export const useProfileStore = defineStore({
  id: "profile",

  state() {
    return {
      fetching: false,
      alreadyFetched: false,

      gender: Gender.Empty,
      preName: "",
      name: "",
      birthDay: "" as string | number,
      birthMonth: "" as string | number,
      birthYear: "" as string | number,
      phoneNumber: "",
      mobileNumber: "",
      street: "",
      houseNumber: "",
      postalCode: "",
      city: "",
      profileImageSrc: "",

      originalGender: Gender.Empty,
      originalPreName: "",
      originalName: "",
      originalBirthDay: "" as string | number,
      originalBirthMonth: "" as string | number,
      originalBirthYear: "" as string | number,
      originalPhoneNumber: "",
      originalMobileNumber: "",
      originalStreet: "",
      originalHouseNumber: "",
      originalPostalCode: "",
      originalCity: "",
      originalProfileImageSrc: "",
    };
  },

  actions: {
    async tryCreateUserProfile(profilePostForm: ProfilePostForm): Promise<ApiResponse> {
      try {
        return await ApiClient.postJson(
          "profile/CreateUserProfile",
          profilePostForm,
        );
      } catch (e: unknown) {
        return await handleApiError(e as Error);
      }
    },

    async tryGetUserProfile(force = false, callstack = 0): Promise<ApiResponse> {
      if (callstack > 9) {
        throw new Error("Maximum callstack reached. Unable to fetch profile.");
      }

      if (this.alreadyFetched && !force) {
        return success("Profil bereits abgefragt.");
      }

      if (this.fetching) {
        // if already fetching, wait a bit and try to get it again
        await new Promise(r => setTimeout(r, 200));

        return await this.tryGetUserProfile(force, callstack + 1);
      }

      try {
        this.fetching = true;
        const response = await ApiClient.getJson<GetUserProfileResponse>("profile/GetUserProfile");

        if (response.success) {
          this.setOriginalProfile(response);
          this.setProfile(response);

          this.alreadyFetched = true;
        }

        return fromResponse(response);
      } catch (e: unknown) {
        this.alreadyFetched = false;

        return await handleApiError(e as Error);
      } finally {
        this.fetching = false;
      }
    },

    async tryUpdateUserProfile(): Promise<ApiResponse> {
      try {
        const updateUserProfileResponse = await ApiClient.postJson(
          "profile/UpdateUserProfile",
          {
            gender: this.gender,
            preName: this.preName,
            name: this.name,
            birthDay: this.birthDay,
            birthMonth: this.birthMonth,
            birthYear: this.birthYear,
            phoneNumber: this.phoneNumber,
            mobileNumber: this.mobileNumber,
            street: this.street,
            houseNumber: this.houseNumber,
            postalCode: this.postalCode,
            city: this.city,
          } as ProfilePostForm,
        );

        return fromResponse(updateUserProfileResponse);
      } catch (e: unknown) {
        return await handleApiError(e as Error);
      }
    },

    async tryUpdateUserProfileImage(): Promise<ApiResponse> {
      try {
        const updateUserProfileResponse = await ApiClient.postJson(
          "profile/UpdateUserProfileImage",
          {
            profileImageSrc: this.profileImageSrc,
          } as ProfileImagePostForm,
        );

        return fromResponse(updateUserProfileResponse);
      } catch (e: unknown) {
        return await handleApiError(e as Error);
      }
    },

    setProfile(profile: GetUserProfileResponse): void {
      this.gender = profile.gender;
      this.preName = profile.preName;
      this.name = profile.name;
      this.birthDay = profile.birthDay;
      this.birthMonth = profile.birthMonth;
      this.birthYear = profile.birthYear;
      this.phoneNumber = profile.phoneNumber;
      this.mobileNumber = profile.mobileNumber;
      this.street = profile.street;
      this.houseNumber = profile.houseNumber;
      this.postalCode = profile.postalCode;
      this.city = profile.city;
      this.profileImageSrc = profile.profileImageSrc;
    },

    setOriginalProfile(profile: GetUserProfileResponse): void {
      this.originalGender = profile.gender;
      this.originalPreName = profile.preName;
      this.originalName = profile.name;
      this.originalBirthDay = profile.birthDay;
      this.originalBirthMonth = profile.birthMonth;
      this.originalBirthYear = profile.birthYear;
      this.originalPhoneNumber = profile.phoneNumber;
      this.originalMobileNumber = profile.mobileNumber;
      this.originalStreet = profile.street;
      this.originalHouseNumber = profile.houseNumber;
      this.originalPostalCode = profile.postalCode;
      this.originalCity = profile.city;
      this.originalProfileImageSrc = profile.profileImageSrc;
    },

    resetProfile(): void {
      this.gender = this.originalGender;
      this.preName = this.originalPreName;
      this.name = this.originalName;
      this.birthDay = this.originalBirthDay;
      this.birthMonth = this.originalBirthMonth;
      this.birthYear = this.originalBirthYear;
      this.phoneNumber = this.originalPhoneNumber;
      this.mobileNumber = this.originalMobileNumber;
      this.street = this.originalStreet;
      this.houseNumber = this.originalHouseNumber;
      this.postalCode = this.originalPostalCode;
      this.city = this.originalCity;
      this.profileImageSrc = this.originalProfileImageSrc;
    },
  },

});
