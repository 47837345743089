




























































































































import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import type {ApiMessage} from "@/api/models/common/ApiResponse";
import {useProductsStore} from "@/store/products";
import SmbToolbar from "@/components/ui/SmbToolbar/SmbToolbar.vue";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import type {IdTag} from "@/api/models/products/IdTag";
import SmbIconButton from "@/components/ui/SmbIconButton/SmbIconButton.vue";
import IdTagColorSelect from "@/components/helper/IdTagColorSelect.vue";
import {mapActions} from "pinia";
import type {AddIdTagPostForm} from "@/api/models/products/AddIdTagPostForm";
import {ErrorReporter} from "@/api/client/ErrorReporter";
import {useBusyStore} from "@/store/busy";
import type {ComponentColor} from "@/components/ui/helpers";
import {fromResponse, handleApiError} from "@/api/helper";
import SmbDropdown from "@/components/ui/SmbDropdown/SmbDropdown.vue";
import SmbDropdownItem from "@/components/ui/SmbDropdown/SmbDropdownItem.vue";
import {IdTagColor} from "@/api/models/products/IdTagColor";
import SmbCheckbox from "@/components/ui/SmbCheckbox/SmbCheckbox.vue";

@Component({
  components: {
    SmbCheckbox,
    SmbDropdownItem,
    SmbDropdown,
    IdTagColorSelect,
    SmbIconButton,
    SmbTextInput,
    SmbApiAlert,
    SmbButton,
    SmbToolbar
  },
  metaInfo(this: EditIdTag) {
    const newIdTag = this.isNewTag;

    return {
      title: newIdTag ? "Neues Gerät" : "ID-TAG Bearbeiten",
    };
  },
  methods: {
    ...mapActions(useProductsStore, ["createIdTag", "updateIdTag"]),
    ...mapActions(useBusyStore, ["setViewport"]),
  },
})
export default class EditIdTag extends Vue {
  @Prop({type: Object, default: null})
  public readonly tag!: IdTag | null;

  public readonly createIdTag!: (form: AddIdTagPostForm) => Promise<ApiMessage>;
  public readonly updateIdTag!: (idTag: IdTag) => Promise<ApiMessage>;
  public readonly setViewport!: (busy: boolean, message?: string, color?: ComponentColor) => void;

  public message: ApiMessage | null = null;

  public idTagId: number | null = null;
  public activationCode = "";
  public idTag: Omit<IdTag, "id"> = {
    securityNumber: "",
    serialNumber: "",
    description: "",
    paired: false,
    activeUntil: null,
    useFor: "",
    color: IdTagColor.Black,
    manufacturer: "",
    model: "",
    isSmartphone: false,
  };

  @Watch("tag", {immediate: true})
  public onTagChanged(val: IdTag | null): void {
    // fill attributes with data from prop
    this.idTag = val ?? this.idTag;
    this.idTagId = val?.id ?? null;
  }

  public get isNewTag(): boolean {
    return !Number.isInteger(this.tag?.id);
  }

  public async onSubmit(): Promise<void> {
    this.setViewport(true, "ID-TAG wird gespeichert...");

    try {
      if (this.isNewTag) {
        const newTag: AddIdTagPostForm = Object.assign({}, this.idTag, {activationCode: this.activationCode});
        const result = await this.createIdTag(newTag);
        this.message = fromResponse(result);
        if (!result.success) {
          return;
        }

        await this.$router.push(`/products?msg=added_id_tag`)
      } else {
        const editedTag: IdTag = Object.assign({}, this.idTag as IdTag, {id: this.idTagId});
        const result = await this.updateIdTag(editedTag);
        this.message = fromResponse(result);
        if (!result.success) {
          return;
        }

        await this.$router.push(`/products/id-tag/${editedTag.id}`)
      }
    } catch (e: unknown) {
      ErrorReporter.report(e as Error);

      this.message = await handleApiError(e as Error);
    } finally {
      this.setViewport(false);
    }
  }

  public onAbort(): void {
    this.$router.back();
  }
}
