




























import Vue from "vue";
import Component from "vue-class-component";

@Component({
  metaInfo: {
    title: "Rechtliches",
  },
})
export default class Legal extends Vue {
}
