




import Vue from "vue";
import Component from "vue-class-component";
import type { ComponentColor } from "../helpers";
import { colorValidator } from "../helpers";
import { Prop } from "vue-property-decorator";

@Component
export default class SmbLoader extends Vue {
  @Prop({ type: String, default: undefined, validator: colorValidator() })
  public readonly color!: ComponentColor | undefined;
}
