import {
  faBars,
  faBell,
  faBug,
  faCamera,
  faChartLine,
  faCheckCircle,
  faEdit,
  faEnvelope,
  faExchangeAlt,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faGripHorizontal,
  faHeart,
  faHistory,
  faKey,
  faLink,
  faList,
  faLock,
  faMapMarkerAlt,
  faMinus,
  faMobileAlt,
  faNewspaper,
  faPen,
  faPlus,
  faShare,
  faShoppingCart,
  faSquare,
  faStickyNote,
  faTable,
  faTimes,
  faTimesCircle,
  faTrash,
  faUndoAlt,
  faUnlink,
  faUser,
  faUsers,
  faCircle as fasCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  faDotCircle,
  faCircle as farCircle,
} from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { smbIdTag } from "@/plugins/icons/smbIdTag";

library.add(faEye);
library.add(faEyeSlash);
library.add(faMobileAlt);
library.add(faList);
library.add(faKey);
library.add(faCheckCircle);
library.add(faSquare);
library.add(faHeart);
library.add(faTimesCircle);
library.add(faUser);
library.add(faTimes);
library.add(faBell);
library.add(faBars);
library.add(fasCircle);
library.add(faNewspaper);
library.add(faPen);
library.add(faCamera);
library.add(faBug);
library.add(faUsers);
library.add(faShoppingCart);
library.add(faEdit);
library.add(faStickyNote);
library.add(faExchangeAlt);
library.add(faEnvelope);
library.add(faChartLine);
library.add(faPlus);
library.add(faMinus);
library.add(faHistory);
library.add(faLink);
library.add(faUnlink);
library.add(smbIdTag);
library.add(farCircle);
library.add(faDotCircle);
library.add(faMapMarkerAlt);
library.add(faLock);
library.add(faShare);
library.add(faTrash);
library.add(faTable);
library.add(faGripHorizontal);
library.add(faUndoAlt);
library.add(faExclamationTriangle);

Vue.component("FaIcon", FontAwesomeIcon);
