











































import Vue from "vue";
import Component from "vue-class-component";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import type { CellDefinition } from "@/components/ui/SmbTable/SmbTable.vue";
import SmbTable from "@/components/ui/SmbTable/SmbTable.vue";
import type { IdTag } from "@/api/models/products/IdTag";
import { Prop } from "vue-property-decorator";
import type { RawLocation } from "vue-router";
import IdTagPairedLabel from "@/components/helper/IdTagPairedLabel.vue";
import { longDate } from "@/filters/dayJs";

Vue.component("IdTagPairedLabel", IdTagPairedLabel);

@Component({
  components: {SmbButton, SmbTable}
})
export default class IdTagTable extends Vue {
  @Prop({ type: Array, required: true })
  public readonly idTags!: IdTag[];

  public selectedIdTags: number[] = [];

  public get anyIdTagSelected(): boolean {
    return this.selectedIdTags.length > 0;
  }

  public get allIdTagsSelected(): boolean {
    return this.idTags.every(i => this.selectedIdTags.some(p => i.id === p));
  }

  public get idTagIconColHeaderIcon(): string | string[] {
    if (this.allIdTagsSelected)
      return "circle";

    if (this.anyIdTagSelected)
      return ['far', 'dot-circle'];

    return ['far', 'circle'];
  }

  public getLinkTo(idTag: IdTag): RawLocation {
    return `/products/id-tag/${idTag.id}`;
  }

  public idTagCellRenderer(idTag: IdTag, key: string): CellDefinition {
    let selected = false;
    switch (key) {
      case "icon":
        if (this.selectedIdTags.some(p => p === idTag.id))
          selected = true;

        return {
          tag: "product-icon",
          attrs: {
            color: selected ? "text-success bg-white rounded-circle" : idTag.color,
            icon: selected ? "check-circle" : "id-tag",
            size: "3x",
          }
        };
      case "description":
        return {
          tag: "id-tag-paired-label",
          attrs: {
            idTag,
          },
        };
      case "activeUntil":
        return {
          tag: "span",
          slot: idTag.activeUntil === null ? "unbegrenzt" : longDate(idTag.activeUntil),
        };
      default:
        return {
          tag: "span",
          slot: idTag[key],
        };
    }
  }

  public async idTagRowClicked(row: IdTag, col: string): Promise<void> {
    if (col !== "icon") {
      await this.$router.push(this.getLinkTo(row));

      return;
    }

    const idx = this.selectedIdTags.findIndex(i => i === row.id);
    if (idx !== -1) {
      this.selectedIdTags.splice(idx, 1);
    } else {
      this.selectedIdTags.push(row.id);
    }
  }

  public idTagHeaderClicked(col: string): void {
    if (col !== "icon")
      return;

    if (this.allIdTagsSelected)
      this.selectedIdTags = [];
    else
      this.selectedIdTags = [
        ...this.idTags.map(i => i.id),
      ];
  }
}
