











import Vue from "vue";
import Component from "vue-class-component";

@Component({
  metaInfo: {
    title: "Hologramm-Codes",
  },
})
export default class Holograms extends Vue {}
