import "./componentHooks";

import type {Route, RouteConfig} from "vue-router";

import type {Component} from "vue";
import DashboardLayout from "../components/layout/DashboardLayout.vue";
import Login from "@/views/auth/Login.vue";
import RecoverPassword from "@/views/auth/RecoverPassword.vue";
import Register from "@/views/auth/Register.vue";
import {UserRole} from "@/api/models/auth/UserRole";
import Vue from "vue";
import VueRouter from "vue-router";
import {useAuthStore} from "@/store/auth";
import {useBusyStore} from "@/store/busy";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/",
    component: DashboardLayout,
    meta: {
      auth: {
        allowRoles: [UserRole.Customer, UserRole.Admin],
      },
    },
    children: [
      {
        path: "blog",
        component: async (): Promise<Component> => import(/* webpackChunkName: "blog" */ "../views/customer/Home.vue") as Component,
      },
      {
        path: "blog/:slug",
        props: true,
        component: async (): Promise<Component> => import(/* webpackChunkName: "blog" */ "../views/customer/BlogEntryView.vue") as Component,
      },
      {
        path: "products",
        component: async (): Promise<Component> => import(/* webpackChunkName: "products" */ "../views/customer/products/Products.vue") as Component,
      },
      {
        path: "products/id-tag/add",
        component: async (): Promise<Component> => import(/* webpackChunkName: "products" */ "../views/customer/products/IdTagLayout.vue") as Component,
        children: [
          {
            name: "add-id-tag",
            path: "",
            component: async (): Promise<Component> => import(/* webpackChunkName: "products" */ "../views/customer/products/id-tag/EditIdTag.vue") as Component,
          },
        ],
      },
      {
        path: "products/id-tag/:id",
        props: true,
        component: async (): Promise<Component> => import(/* webpackChunkName: "products" */ "../views/customer/products/IdTagLayout.vue") as Component,
        children: [
          {
            name: "view-id-tag",
            path: "",
            component: async (): Promise<Component> => import(/* webpackChunkName: "products" */ "../views/customer/products/id-tag/ViewIdTag.vue") as Component,
          },
          {
            name: "edit-id-tag",
            path: "edit",
            component: async (): Promise<Component> => import(/* webpackChunkName: "products" */ "../views/customer/products/id-tag/EditIdTag.vue") as Component,
          },
          {
            name: "locate-id-tag",
            path: "locate",
            component: async (): Promise<Component> => import(/* webpackChunkName: "products" */ "../views/customer/products/id-tag/LocateIdTag.vue") as Component,
          },
          {
            name: "delete-id-tag",
            path: "delete",
            component: async (): Promise<Component> => import(/* webpackChunkName: "products" */ "../views/customer/products/id-tag/DeleteIdTag.vue") as Component,
          },
          {
            name: "reportlost-id-tag",
            path: "reportlost",
            component: async (): Promise<Component> => import(/* webpackChunkName: "products" */ "../views/customer/products/id-tag/LostIdTag.vue") as Component,
          },
        ],
      },
      {
        path: "products/key-keeper/add",
        component: async (): Promise<Component> => import(/* webpackChunkName: "products" */ "../views/customer/products/KeyKeeperLayout.vue") as Component,
        children: [
          {
            name: "add-key-keeper",
            path: "",
            component: async (): Promise<Component> => import(/* webpackChunkName: "products" */ "../views/customer/products/key-keeper/EditKeyKeeper.vue") as Component,
          },
        ],
      },
      {
        path: "products/key-keeper/:id",
        props: true,
        component: async (): Promise<Component> => import(/* webpackChunkName: "products" */ "../views/customer/products/KeyKeeperLayout.vue") as Component,
        children: [
          {
            name: "view-key-keeper",
            path: "",
            component: async (): Promise<Component> => import(/* webpackChunkName: "products" */ "../views/customer/products/key-keeper/ViewKeyKeeper.vue") as Component,
          },
          {
            name: "edit-key-keeper",
            path: "edit",
            component: async (): Promise<Component> => import(/* webpackChunkName: "products" */ "../views/customer/products/key-keeper/EditKeyKeeper.vue") as Component,
          },
          {
            name: "delete-key-keeper",
            path: "delete",
            component: async (): Promise<Component> => import(/* webpackChunkName: "products" */ "../views/customer/products/key-keeper/DeleteKeyKeeper.vue") as Component,
          },
          {
            name: "reportlost-key-keeper",
            path: "reportlost",
            component: async (): Promise<Component> => import(/* webpackChunkName: "products" */ "../views/customer/products/key-keeper/LostKeyKeeper.vue") as Component,
          },
        ],
      },
      {
        path: "be-on",
        component: async (): Promise<Component> => import(/* webpackChunkName: "services" */ "../views/customer/services/BE.ON.vue") as Component,
      },
      {
        path: "be-in",
        component: async (): Promise<Component> => import(/* webpackChunkName: "services" */ "../views/customer/services/BE.IN.vue") as Component,
      },
      {
        path: "be-protect",
        component: async (): Promise<Component> => import(/* webpackChunkName: "services" */ "../views/customer/services/BE.Protect.vue") as Component,
      },
      {
        path: "report-find",
        meta: {
          auth: {
            allowRoles: [UserRole.Guest],
          },
        },
        component: async (): Promise<Component> => import(/* webpackChunkName: "services" */ "../views/customer/ReportFind.vue") as Component,
      },
      {
        path: "account",
        component: async (): Promise<Component> => import(/* webpackChunkName: "settings" */ "../views/customer/account/Account.vue") as Component,
      },
      {
        path: "profile",
        component: async (): Promise<Component> => import(/* webpackChunkName: "settings" */ "../views/customer/account/Profile.vue") as Component,
      },
      {
        path: "settings",
        component: async (): Promise<Component> => import(/* webpackChunkName: "settings" */ "../views/customer/account/Settings.vue") as Component,
      },
      {
        path: "legal",
        component: async (): Promise<Component> => import(/* webpackChunkName: "services" */ "../views/customer/account/Legal.vue") as Component,
      },
      {
        path: "support",
        component: async (): Promise<Component> => import(/* webpackChunkName: "services" */ "../views/customer/account/Support.vue") as Component,
      },
      {
        path: "admin",
        component: async (): Promise<Component> => import(/* webpackChunkName: "admin" */ "../components/layout/AdminLayout.vue") as Component,
        meta: {
          auth: {
            denyRoles: [UserRole.Customer],
          },
        },
        children: [
          {
            path: "dashboard",
            component: async (): Promise<Component> => import(/* webpackChunkName: "admin" */ "../views/admin/Dashboard.vue") as Component,
          },
          {
            path: "permissions",
            component: async (): Promise<Component> => import(/* webpackChunkName: "admin" */ "../views/admin/Permissions.vue") as Component,
          },
          {
            path: "customers",
            component: async (): Promise<Component> => import(/* webpackChunkName: "admin" */ "../views/admin/Customers.vue") as Component,
          },
          {
            path: "customer/:id",
            props: true,
            component: async (): Promise<Component> => import(/* webpackChunkName: "admin" */ "../views/admin/CustomerDetails.vue") as Component,
          },
          {
            path: "devices",
            component: async (): Promise<Component> => import(/* webpackChunkName: "admin" */ "../views/admin/Devices.vue") as Component,
          },
          {
            path: "device/:id",
            props: true,
            component: async (): Promise<Component> => import(/* webpackChunkName: "admin" */ "../views/admin/DeviceDetails.vue") as Component,
          },
          {
            path: "orders",
            component: async (): Promise<Component> => import(/* webpackChunkName: "admin" */ "../views/admin/Orders.vue") as Component,
          },
          {
            path: "state-management",
            component: async (): Promise<Component> => import(/* webpackChunkName: "admin" */ "../views/admin/StateManagement.vue") as Component,
          },
          {
            path: "holograms",
            component: async (): Promise<Component> => import(/* webpackChunkName: "admin" */ "../views/admin/Holograms.vue") as Component,
          },
          {
            path: "transfer",
            component: async (): Promise<Component> => import(/* webpackChunkName: "admin" */ "../views/admin/Transfer.vue") as Component,
          },
          {
            path: "mail-management",
            component: async (): Promise<Component> => import(/* webpackChunkName: "admin" */ "../views/admin/MailManagement.vue") as Component,
          },
          {
            path: "statistics",
            component: async (): Promise<Component> => import(/* webpackChunkName: "admin" */ "../views/admin/Statistics.vue") as Component,
          },
          {
            path: "debug",
            component: async (): Promise<Component> => import(/* webpackChunkName: "admin" */ "../views/admin/Debug.vue") as Component,
          },
        ]
      },
    ],
  },
  {
    path: "/login",
    component: Login,
    meta: {
      auth: {
        allowRoles: [UserRole.Guest],
      },
      loader: "global",
    },
  },
  {
    path: "/register",
    component: Register,
    meta: {
      auth: {
        allowRoles: [UserRole.Guest, UserRole.Customer, UserRole.Admin],
      },
      loader: "global",
    },
  },
  {
    path: "/recover-password",
    component: RecoverPassword,
    meta: {
      auth: {
        allowRoles: [UserRole.Guest],
      },
      loader: "global",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to: Route, from: Route, next) => {
  // show loader before page transition
  const busy = useBusyStore();
  if (to.meta?.loader && to.meta.loader === "global") {
    busy.setGlobal(true);
  } else {
    busy.setViewport(true);
  }

  // small delay before redirecting
  await new Promise((r) => setTimeout(r, 50));

  const allowedRoles = to.matched.flatMap(r => r.meta.auth?.allowRoles ?? []);
  const deniedRoles = to.matched.flatMap(r => r.meta.auth?.denyRoles ?? []);

  const auth = useAuthStore();
  if (!auth.isAuthenticated) {
    await auth.tryAuthenticate();
  }

  const hasAllowedRole = allowedRoles.some(auth.hasRole);
  const hasDeniedRole = deniedRoles.some(auth.hasRole);

  const accessAllowed = !hasDeniedRole && hasAllowedRole;

  if (!accessAllowed) {
    const isGuest = auth.hasRole(UserRole.Guest);
    if (isGuest) {
      // guest needs to authenticate
      Vue.$toast.warning("Du musst dich zuerst einloggen.");

      next(`/login?r=${encodeURIComponent(to.fullPath)}`);
      return;
    }

    Vue.$toast.warning("Du hast keine Berechtigung darauf zuzugreifen.");

    // don't redirect back if no previous route exists and also don't redirect back if the previous page was the
    // login/register.
    if (from.matched.length === 0 || from.matched[0].path === "/login" || from.matched[0].path === "/register") {
      next("/blog"); // redirect to homepage
    } else {
      next(false); // redirect back

      // afterEach is not called when aborting navigation, so we need to reset busy states now
      busy.setViewport(false);
      busy.setGlobal(false);
    }
    return;
  }

  // check if registration is complete and redirect there if not
  if (
    auth.isAuthenticated &&
    !auth.registrationCompleted &&
    to.path !== "/register"
  ) {
    next("/register");

    return;
  }

  if (to.path === "/") {
    if (from.path === "/blog") {
      // abort nav
      next(false);

      // disable loader because afterEach is not being called when aborting navigation
      busy.setViewport(false);
      busy.setGlobal(false);
    } else {
      next("/blog");
    }

    return;
  }

  next();
});

router.afterEach(() => {
  const busy = useBusyStore();

  busy.setViewport(false);
  busy.setGlobal(false);
});

export default router;
