import type Vue from "vue";
import {useBusyStore} from "@/store/busy";
import {fail} from "@/api/helper";
import type {NavigationGuardNext} from "vue-router";
import type {ApiResponse} from "@/api/models/common/ApiResponse";
import {refreshTitle} from "@/mixins/TitleFromMeta";

async function loadBeforeEnter<T, V extends Vue = Vue>(
  next: NavigationGuardNext<V>,
  request: Promise<T>,
  store: (vm: V, data: T | null, error: ApiResponse | null) => void
): Promise<void> {
  const busyStore = useBusyStore();
  busyStore.setViewport(true);

  let data: T | null = null;
  let error: ApiResponse | null = null;

  try {
    data = await request;
  } catch (e: unknown) {
    error = fail((e as Error).message);
  }

  next(vm => {
    store(vm, data, error);

    refreshTitle(vm);
  });

  busyStore.setViewport(false);
}

export default loadBeforeEnter;
