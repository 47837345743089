








































import Vue from "vue";
import Component from "vue-class-component";

import { useBusyStore } from "@/store/busy";
import { mapActions } from "pinia";

import { fail, fromResponse, handleApiError } from "@/api/helper";

import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import SmbTextArea from "@/components/ui/SmbTextArea/SmbTextArea.vue";
import SmbSelect from "@/components/ui/SmbSelect/SmbSelect.vue";
import type { ApiMessage, ApiResponse } from "@/api/models/common/ApiResponse";
import type { SendMailPostForm } from "@/api/models/support/SendMailPostForm";
import ApiClient from "@/api/client/ApiClient";

@Component({
  components: { SmbApiAlert, SmbButton, SmbTextInput, SmbTextArea, SmbSelect },
  metaInfo: {
    title: "Support",
  },
  methods: {
    ...mapActions(useBusyStore, ["setViewport"]),
  },
})
export default class Support extends Vue {
  public subject = "";
  public message = "";

  public sendMailMessage: ApiMessage | null = null;
  public processing = false;

  public readonly setViewport!: (busy: boolean, message?: string) => void;

  public async sendMail(): Promise<void> {
    try {
      this.processing = true;
      this.setViewport(true, "E-Mail wird gesendet …");
      this.sendMailMessage = await this.trySendMail();
    } catch (e: unknown) {
      this.sendMailMessage = fail("Fehler beim Senden der Anfrage.");
    } finally {
      this.processing = false;
      this.setViewport(false);
    }
  }

  public async trySendMail(): Promise<ApiResponse> {
    try {
      const sendMailResponse = await ApiClient.postJson("support/SendMail", {
        subject: this.subject,
        message: this.message,
      } as SendMailPostForm);

      return fromResponse(sendMailResponse);
    } catch (e: unknown) {
      return await handleApiError(e as Error);
    }
  }
}
