














import Vue from "vue";
import Component from "vue-class-component";
import type { ComponentColor } from "@/components/ui/helpers";
import SmbAlert from "./SmbAlert.vue";
import type { ApiMessage } from "@/api/models/common/ApiResponse";
import { Prop, PropSync } from "vue-property-decorator";

@Component({
  components: { SmbAlert },
})
export default class SmbApiAlert extends Vue {
  @Prop({ default: null }) public readonly message!: ApiMessage | null;

  @PropSync("closeable", { type: Boolean, default: false })
  public readonly closeableVal!: boolean;

  public get visible(): boolean {
    return this.message !== null;
  }

  public get text(): string | undefined {
    return this.message?.message;
  }

  public get titleColor(): ComponentColor {
    return this.message?.success ? "success" : "danger";
  }

  public get title(): string {
    return this.message?.success ? "Erfolgreich" : "Fehler";
  }
}
