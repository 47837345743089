
























































import Vue from "vue";
import Component from "vue-class-component";
import { IdTagColor } from "@/api/models/products/IdTagColor";
import { Model, Prop } from "vue-property-decorator";

@Component
export default class IdTagColorSelect extends Vue {
  @Model("change", {type: String, default: IdTagColor.Black})
  public readonly model!: IdTagColor;

  @Prop({ type: Boolean, default: false })
  public readonly disabled!: boolean;

  public readonly IdTagColor = IdTagColor;

  public changeTo(color: IdTagColor): void {
    if (this.disabled)
      return;

    this.$emit('change', color);
  }
}
