import Vue from "vue";
import dayjs from "dayjs";

// https://day.js.org/docs/en/i18n/changing-locale
import "dayjs/locale/de";
dayjs.locale('de');

export function date(date: string | number): string {
  return dayjs(date).format("DD.MM.YY");
}

Vue.filter('date', date);

export function longDate(date: string | number): string {
  return dayjs(date).format("DD. MMMM YYYY");
}

Vue.filter('longDate', longDate);

export function shortDateTime(date: string | number): string {
  return dayjs(date).format("DD.MM.YY HH:mm:ss");
}

Vue.filter('shortDateTime', shortDateTime);
