














































































































































































































import type { ApiMessage } from "@/api/models/common/ApiResponse";
import type { Customer } from "@/api/models/customers/Customer";
import loadBeforeEnter from "@/components/helper/loadBeforeEnter";
import { useCustomerStore } from "@/store/customerStore";
import Vue from "vue";
import Component from "vue-class-component";
import type { NavigationGuardNext, RawLocation, Route } from "vue-router";
import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import SmbSelect from "@/components/ui/SmbSelect/SmbSelect.vue";
import SmbTable from "@/components/ui/SmbTable/SmbTable.vue";
import { Gender } from "@/api/models/auth/Gender";
import type { CellDefinition } from "@/components/ui/SmbTable/SmbTable.vue";
import type { Device } from "@/api/models/devices/Device";
import { DeviceState } from "@/api/models/devices/Device";
import dayjs from "dayjs";

@Component({
  components: { SmbApiAlert, SmbButton, SmbTextInput, SmbSelect, SmbTable },
  metaInfo: {
    title: "Kunden-Details",
  },
})
export default class CustomerDetails extends Vue {
  public customer: Customer | null = null;
  public message: ApiMessage | null = null;

  public readonly Gender = Gender;

  public async beforeRouteEnter(
    to: Route,
    from: Route,
    next: NavigationGuardNext<this>
  ): Promise<void> {
    const customerStore = useCustomerStore();

    await loadBeforeEnter(
      next,
      customerStore.getcustomerById(Number(to.params.id)),
      (vm, customer, error) => {
        vm.message = error;
        vm.customer = customer;
      }
    );
  }

  public customerCellRenderer(device: Device, key: string): CellDefinition {
    if (key.toUpperCase() === "ACTIVEUNTIL") {
      if (
        device.product &&
        (device.product as string).toUpperCase() === "SMARTPHONE"
      ) {
        return {
          tag: "span",
          slot: dayjs(device[key] as string).format("YYYY MMMM DD"),
        };
      } else {
        return {
          tag: "span",
          slot: "Unbegrenzt",
        };
      }
    } else if (key.toUpperCase() === "STATE") {
      let stateText = "";

      switch (device[key]) {
        case DeviceState.Owned:
          stateText = "Im Besitz";
          break;
        case DeviceState.Lost:
          stateText = "Verloren gemeldet";
          break;
        case DeviceState.Found:
          stateText = "Fund gemeldet";
          break;
        case DeviceState.Progress:
          stateText = "In Bearbeitung";
          break;
        case DeviceState.Return:
          stateText = "In Rückführung";
          break;
        case DeviceState.Closed:
          stateText = "Abgeschlossen";
          break;
      }

      return {
        tag: "span",
        slot: stateText,
      };
    }

    return {
      tag: "span",
      slot: device[key],
    };
  }

  public async deviceRowClicked(row: Device): Promise<void> {
    await this.$router.push(this.getLinkTo(row));
    return;
  }

  public getLinkTo(device: Device): RawLocation {
    return `/admin/device/${device.id}`;
  }
}
