import { defineStore } from "pinia";
import type { BlogEntry } from "@/api/models/blog/BlogEntry";
import type { BlogErrorResponse, BlogPostsResponse, BlogResponse } from "@/api/models/blog/BlogResponse";

interface PagedEntries {
  page: number;
  entries: BlogEntry[];
}

const baseUrl = process.env.VUE_APP_BLOG_URL as string;
const apiKey = process.env.VUE_APP_BLOG_API_KEY as string;

function buildUrl(
  data: Record<string, null | unknown>
): string {
  let path = "/ghost/api/v3/content/posts/";
  if (Object.prototype.hasOwnProperty.call(data, "id")) {
    path += data.id;

    delete data.id;
  } else if (Object.prototype.hasOwnProperty.call(data, "slug")) {
    path += "slug/";
    path += data.slug;

    delete data.slug;
  }

  const url = new URL(path, baseUrl);
  const params = Object.assign({}, data, { key: apiKey });
  for (const key of Object.keys(params)) {
    if (params[key] === null)
      continue;

    url.searchParams.set(key, String(params[key]));
  }

  return url.toString();
}

export const useBlogStore = defineStore({
  id: "blog",

  state: () => ({
    pages: [] as PagedEntries[],
    nextPage: null as number | null,
  }),

  getters: {
    posts: (state) => state
      .pages
      .map((r: PagedEntries) => r.entries)
      .flatMap(e => e),
  },

  actions: {
    async loadPosts(page: number | null, limit = 15): Promise<BlogPostsResponse> {
      const result = await fetch(buildUrl({ page, limit }));
      const response: BlogResponse = await result.json();

      if (Object.prototype.hasOwnProperty.call(response, "errors")) {
        throw new Error((response as BlogErrorResponse).errors[0].message);
      }

      const postsResponse: BlogPostsResponse = response as BlogPostsResponse;
      const existingIndex = this.pages.findIndex(p => p.page === postsResponse.meta.pagination.page);
      if (existingIndex > -1) {
        this.pages[existingIndex].entries = postsResponse.posts;
      } else {
        this.pages.push({
          page: postsResponse.meta.pagination.page,
          entries: postsResponse.posts,
        });
      }

      return postsResponse;
    },

    async loadNextPage(limit = 15): Promise<BlogPostsResponse> {
      const response = await this.loadPosts(this.nextPage, limit);

      this.nextPage = response.meta.pagination.next;

      return response;
    },

    async getBySlug(slug: string): Promise<BlogEntry> {
      const existingPost = this.pages.flatMap(p => p.entries).find(e => e.slug === slug);
      if (existingPost)
        return existingPost;

      const result = await fetch(buildUrl({ slug }));
      const response: BlogResponse = await result.json();

      if (Object.prototype.hasOwnProperty.call(response, "errors")) {
        throw new Error((response as BlogErrorResponse).errors[0].message);
      }

      return (response as BlogPostsResponse).posts[0];
    }
  }
});
