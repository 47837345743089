export class ApiError extends Error {
  public readonly response;

  public constructor(response: Response, message?: string) {
    super(message);

    super.name = "ApiError";

    this.response = response;
  }
}
