





























import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import SmbIconButton from "@/components/ui/SmbIconButton/SmbIconButton.vue";
import type {ComponentColor} from "@/components/ui/helpers";
import {colorValidator} from "@/components/ui/helpers";

export interface ToolbarItem {
  icon?: string;
  topContent?: string;
  text?: string;
  click: (e: PointerEvent) => void;
  color?: ComponentColor;
  iconColor?: ComponentColor;
  iconSize?: string;
}

@Component({
  components: {SmbIconButton}
})
export default class SmbToolbar extends Vue {
  @Prop({type: Array, default: undefined})
  public readonly items!: ToolbarItem[] | undefined;

  @Prop({type: Boolean, default: false})
  public readonly inline!: boolean;

  @Prop({type: String, default: "black", validator: colorValidator()})
  public readonly color!: ComponentColor;

  @Prop({type: String, default: "warning", validator: colorValidator()})
  public readonly iconColor!: ComponentColor;
}
