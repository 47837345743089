
































import type { ApiResponse } from "@/api/models/common/ApiResponse";
import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  components: { SmbApiAlert },
  metaInfo: {
    titleTemplate: "%s | sendmeback"
  }
})
export default class AuthLayout extends Vue {
  @Prop({ type: String, required: true })
  public readonly heading!: string;

  @Prop({ type: String, required: true })
  public readonly topic!: string;

  @Prop({ type: Object, default: null })
  public readonly errorMessage!: ApiResponse | null;
}
