import { useLayoutStore } from "@/store/layout";
import Vue from "vue";

export const refreshTitle = function (vm: Vue): void {
  const layout = useLayoutStore();

  // refresh() is the only way to access the title of the current page but also causes a double refresh of metadata
  const meta = vm.$meta().refresh().metaInfo.titleChunk;
  if (!meta) {
    layout.title = null;

    return;
  }

  layout.title = meta;
};

Vue.mixin({
  beforeRouteEnter(to, from, next): void {
    next(refreshTitle);
  }
});
