









































import type { SmbInput } from "@/components/ui/SmbInput";
import Vue from "vue";
import Component from "vue-class-component";
import { ModelSync, Prop, Ref } from "vue-property-decorator";

@Component
export default class SmbCheckbox extends Vue implements SmbInput {
  @Ref()
  public readonly element!: HTMLDivElement;

  @ModelSync('checked', 'toggle', { type: Boolean, default: false })
  public readonly value!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly required!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly readonly!: boolean;

  @Prop({
    type: Number,
    default: undefined,
    validator: (val: unknown) => typeof val === "undefined" || typeof val === "number"
  })
  public tabindex!: number | undefined;

  public triedSubmitting = false;

  public get actualTabIndex(): number | undefined {
    return this.disabled ? -1 : this.tabindex;
  }

  public toggle(): void {
    if (this.disabled || this.readonly) {
      return; // don't change value
    }

    this.$emit('toggle', !this.value);
  }

  public keydown(e: KeyboardEvent): void {
    if (e.key !== " ") {
      return;
    }

    e.preventDefault();

    this.toggle();
  }

  public get isValid(): boolean {
    return !this.required || this.value;
  }

  public focus(): void {
    this.element.focus();
  }

  public resetError(): void {
    this.triedSubmitting = false;
  }
}
