



































































import Vue from "vue";
import Component from "vue-class-component";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import type {IdTag} from "@/api/models/products/IdTag";
import {Prop} from "vue-property-decorator";
import ApiClient from "@/api/client/ApiClient";
import {LMap, LMarker, LTileLayer} from "vue2-leaflet";
import type {ApiMessage, ApiResponse} from "@/api/models/common/ApiResponse";
import type {LatLng} from "leaflet";
import {latLng} from "leaflet";
import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import {fromResponse} from "@/api/helper";
import SmbIconButton from "@/components/ui/SmbIconButton/SmbIconButton.vue";
import type {NavigationGuardNext, Route} from "vue-router";

@Component({
  components: {
    SmbIconButton,
    SmbButton,
    SmbApiAlert,
    LMap,
    LTileLayer,
    LMarker,
  },
  metaInfo: {
    title: "ID-TAG Orten"
  }
})
export default class LocateIdTag extends Vue {
  @Prop({type: Object, default: null})
  public tag!: IdTag | null;

  public readonly tileMap = "https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png";
  public readonly attribution = '&copy; <a target="_blank" href="https://osm.org/copyright">OpenStreetMap</a> contributors';

  public location: LatLng | null = null;
  public locationUpdateRequested = false;
  public lastFetched: number | null = null;
  public lastLocationUpdate: number | null = null;
  public zoom = 18;
  public checkInterval = 60000; // 60s
  public checkIntervalHandle = -1;
  public message: ApiMessage | null = null;

  public async mounted(): Promise<void> {
    this.restartCheckInterval();

    await this.getLocation();
  }

  public beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    this.stopCheckInterval();

    next();
  }

  public async requestLocation(): Promise<void> {
    const result = await ApiClient.getJson("Smartphone/RequestLocationUpdate?deviceId=" + this.tag?.id);

    this.message = fromResponse(result);

    this.checkInterval = 10000; // 10s
    this.restartCheckInterval();

    this.locationUpdateRequested = true;
  }

  public restartCheckInterval(): void {
    if (this.checkIntervalHandle > 0)
      clearInterval(this.checkIntervalHandle);

    this.checkIntervalHandle = setInterval(() => this.getLocation(), this.checkInterval);
  }

  public stopCheckInterval(): void {
    if (this.checkIntervalHandle > 0)
      clearInterval(this.checkIntervalHandle);

    this.checkInterval = 60000; // 60s
  }

  public async getLocation(): Promise<void> {
    const result = await ApiClient.getJson<ApiResponse & { latitude: string | null; longitude: string | null; lastUpdateUnix: number | null }>("Smartphone/GetLocation?deviceId=" + this.tag?.id);

    if (result.latitude === null || result.longitude === null)
      this.location = null;
    else
      this.location = latLng(Number(result.latitude), Number(result.longitude));

    this.lastLocationUpdate = result.lastUpdateUnix;
    this.lastFetched = new Date().valueOf();
    this.message = null;
  }
}
