




















import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

import * as blackKeeper from "@/assets/smb-key-keeper-black.256.png";
import * as pinkKeeper from "@/assets/smb-key-keeper-pink.256.png";
import * as oldBlackKeeper from "@/assets/smb-key-keeper-old-black.256.png";
import * as oldPinkKeeper from "@/assets/smb-key-keeper-old-pink.256.png";
import {KeyKeeperColor} from "@/api/models/products/KeyKeeperColor";

@Component
export default class KeyKeeperSticker extends Vue {
  @Prop({ type: String, default: undefined })
  public readonly code!: string | undefined;

  @Prop({ type: String, default: undefined })
  public readonly color!: KeyKeeperColor | undefined;

  @Prop({type: [String, Number], default: 1})
  public readonly scale!: string | number;

  public get imageUrl(): string | undefined {
    if (typeof this.color === "undefined")
      return undefined;

    switch (this.color) {
      case KeyKeeperColor.Black:
      case KeyKeeperColor.Green:
        return blackKeeper.default;
      case KeyKeeperColor.Pink:
      case KeyKeeperColor.White:
      case KeyKeeperColor.Gold:
        return pinkKeeper.default;
      case KeyKeeperColor.BlackOld:
        return oldBlackKeeper.default;
      case KeyKeeperColor.PinkOld:
        return oldPinkKeeper.default;
      default:
        throw new Error(`KEYKEEPER color ${this.color} is not implemented.`);
    }
  }
}
