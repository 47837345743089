import ApiClient from "@/api/client/ApiClient";
import type { ErrorReport } from "@/api/models/ErrorReport";
import Vue from "vue";

export class ErrorReporter {
  private static readonly discardErrors = (process.env.VUE_APP_REPORT_ERRORS as string) === "false";

  public static register(): void {
    Vue.config.errorHandler = (err, vm, info): void => { this.reportVue(err, vm, info); throw err; };

    console.debug("Global error reporter registered.");
  }

  public static report(error: Error): void {
    const errorReport: ErrorReport = this.createErrorReport(error);

    this.reportToApi(errorReport);
  }

  private static createErrorReport(error: Error): ErrorReport {
    return {
      name: error.name,
      message: error.message,
      stack: error.stack as string,
      location: document.location.href
    }
  }

  private static reportVue(error: Error, vm: Vue, info: string): void {
     const errorReport: ErrorReport = {
      ...this.createErrorReport(error),
      info: info,
      data: JSON.stringify(vm.$data),
      component: vm.$options.name,
    }

    this.reportToApi(errorReport);
  }

  private static reportToApi(payload: ErrorReport): void {
    if (this.discardErrors) {
      console.debug("Discarding error report since VUE_APP_REPORT_ERRORS is false.", payload);

      return;
    }

    ApiClient.post("Logging/Frontend", payload)
      .then((r) => {
        if (!r.ok) {
          console.error("Reported error to backend, but response was not OK.");
        } else {
          console.trace("Successfully reported error.");
        }
      })
      .catch((e) => {
        console.error("Failed to report an error to backend.", e);
        console.error("Error payload:", payload);
      });
  }
}
