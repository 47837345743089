






















import Vue from "vue";
import Component from "vue-class-component";
import ChangeMailForm from "@/components/forms/ChangeMailForm.vue";
import ChangePasswordForm from "@/components/forms/ChangePasswordForm.vue";
import DeleteAccountForm from "@/components/forms/DeleteAccountForm.vue";

@Component({
  components: {
    DeleteAccountForm,
    ChangePasswordForm,
    ChangeMailForm,
  },
  metaInfo: {
    title: "Account",
  },
})
export default class Account extends Vue {}
