import {defineStore} from "pinia";
import type {IdTag} from "@/api/models/products/IdTag";
import type {KeyKeeper} from "@/api/models/products/KeyKeeper";
import type {GetProductsResponse} from "@/api/models/products/GetProductsResponse";
import type {AddIdTagPostForm} from "@/api/models/products/AddIdTagPostForm";
import ApiClient from "@/api/client/ApiClient";
import type {ApiMessage} from "@/api/models/common/ApiResponse";
import {handleApiError} from "@/api/helper";
import type {AddKeyKeeperPostForm} from "@/api/models/products/AddKeyKeeperPostForm";
import type {UpdateIdTagPostForm} from "@/api/models/products/UpdateIdTagPostForm";
import type {UpdateKeyKeeperPostForm} from "@/api/models/products/UpdateKeyKeeperPostForm";

export const useProductsStore = defineStore({
  id: "products",

  state() {
    return {
      allQueried: false,
      idTags: [] as IdTag[],
      keyKeepers: [] as KeyKeeper[],
    };
  },

  actions: {
    async getAll(force = false): Promise<void> {
      if (this.allQueried && !force)
        return;

      const response = await ApiClient.getJson<GetProductsResponse>(`Device/GetUserDevices`);
      if (!response.success)
        throw new Error(response.message);

      if (response.idTags) {
        this.idTags = [];
        for (const idTag of response.idTags) {
          this.idTags.push(idTag);
        }
      }

      if (response.keyKeepers) {
        this.keyKeepers = [];
        for (const keyKeeper of response.keyKeepers) {
          this.keyKeepers.push(keyKeeper);
        }
      }

      this.allQueried = true;
    },

    async getIdTagById(id: number): Promise<IdTag> {
      if (!this.allQueried) {
        await this.getAll();
      }

      const idTag = this.idTags.find(i => i.id === id);
      if (typeof idTag !== "undefined")
        return idTag;

      throw new Error("ID-TAG nicht gefunden");
    },

    async createIdTag(form: AddIdTagPostForm): Promise<ApiMessage> {
      try {
        return await ApiClient.postJson("Device/AddIdTag", form);
      } catch (e: unknown) {
        return await handleApiError(e as Error);
      }
    },

    async updateIdTag(idTag: IdTag): Promise<ApiMessage> {
      try {
        const form: UpdateIdTagPostForm = {
          securityNumber: idTag.securityNumber,
          description: idTag.description,
          manufacturer: idTag.manufacturer,
          model: idTag.model,
          serialNumber: idTag.serialNumber,
          color: idTag.color,
        }

        return await ApiClient.postJson("Device/UpdateIdTag", form);
      } catch (e: unknown) {
        return await handleApiError(e as Error);
      }
    },

    async getKeyKeeperById(id: number): Promise<KeyKeeper> {
      if (!this.allQueried) {
        await this.getAll();
      }

      const keyKeeper = this.keyKeepers.find(i => i.id === id);
      if (typeof keyKeeper !== "undefined")
        return keyKeeper;

      throw new Error("KEYKEEPER nicht gefunden");
    },

    async createKeyKeeper(form: AddKeyKeeperPostForm): Promise<ApiMessage> {
      try {
        return await ApiClient.postJson("Device/AddKeyKeeper", form);
      } catch (e: unknown) {
        return await handleApiError(e as Error);
      }
    },

    async updateKeyKeeper(keyKeeper: KeyKeeper): Promise<ApiMessage> {
      try {
        const form: UpdateKeyKeeperPostForm = {
          securityNumber: keyKeeper.securityNumber,
          description: keyKeeper.description,
          color: keyKeeper.color,
        }

        return await ApiClient.postJson("Device/UpdateKeyKeeper", form);
      } catch (e: unknown) {
        return await handleApiError(e as Error);
      }
    },
  },
});
