






























































































































import Vue from "vue";
import Component from "vue-class-component";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";

@Component({
  components: { SmbButton },
  metaInfo: {
    title: "BE.IN",
  },
})
export default class BEIN extends Vue {}
