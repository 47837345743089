








































import Vue from "vue";
import Component from "vue-class-component";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import type {CellDefinition} from "@/components/ui/SmbTable/SmbTable.vue";
import SmbTable from "@/components/ui/SmbTable/SmbTable.vue";
import type {KeyKeeper} from "@/api/models/products/KeyKeeper";
import {Prop} from "vue-property-decorator";
import type {RawLocation} from "vue-router";

@Component({
  components: {SmbButton, SmbTable}
})
export default class KeyKeeperTable extends Vue {
  @Prop({ type: Array, required: true })
  public readonly keyKeepers!: KeyKeeper[];

  public selectedKeyKeepers: number[] = [];

  public get anyKeyKeeperSelected(): boolean {
    return this.selectedKeyKeepers.length > 0;
  }

  public get allKeyKeepersSelected(): boolean {
    return this.keyKeepers.every(i => this.selectedKeyKeepers.some(p => i.id === p));
  }

  public get keyKeeperIconColHeaderIcon(): string | string[] {
    if (this.allKeyKeepersSelected)
      return "circle";

    if (this.anyKeyKeeperSelected)
      return ['far', 'dot-circle'];

    return ['far', 'circle'];
  }

  public getLinkTo(keyKeeper: KeyKeeper): RawLocation {
    return `/products/key-keeper/${keyKeeper.id}`;
  }

  public keyKeeperCellRenderer(keyKeeper: KeyKeeper, key: string): CellDefinition {
    let selected = false;
    switch (key) {
      case "icon":
        if (this.selectedKeyKeepers.some(p => p === keyKeeper.id))
          selected = true;

        return {
          tag: "product-icon",
          attrs: {
            color: selected ? "text-success bg-white rounded-circle" : keyKeeper.color,
            icon: selected ? "check-circle" : "id-tag",
            size: "3x",
          }
        };
      default:
        return {
          tag: "span",
          slot: keyKeeper[key],
        };
    }
  }

  public async keyKeeperRowClicked(row: KeyKeeper, col: string): Promise<void> {
    if (col !== "icon") {
      await this.$router.push(this.getLinkTo(row));

      return;
    }

    const idx = this.selectedKeyKeepers.findIndex(i => i === row.id);
    if (idx !== -1) {
      this.selectedKeyKeepers.splice(idx, 1);
    } else {
      this.selectedKeyKeepers.push(row.id);
    }
  }

  public keyKeeperHeaderClicked(col: string): void {
    if (col !== "icon")
      return;

    if (this.allKeyKeepersSelected)
      this.selectedKeyKeepers = [];
    else
      this.selectedKeyKeepers = [
        ...this.keyKeepers.map(i => i.id),
      ];
  }
}
