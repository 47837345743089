




































import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import KeyKeeperSticker from "@/components/helper/KeyKeeperSticker.vue";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import ApiClient from "@/api/client/ApiClient";
import {fromResponse, handleApiError} from "@/api/helper";
import type {ApiMessage} from "@/api/models/common/ApiResponse";
import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import type {KeyKeeper} from "@/api/models/products/KeyKeeper";

@Component({
  components: {SmbApiAlert, SmbButton, KeyKeeperSticker, SmbTextInput},
  metaInfo(this: DeleteKeyKeeper) {
    const keeper = this.keeper;

    return {
      title: keeper?.description ?? "KEYKEEPER",
    };
  },
})
export default class DeleteKeyKeeper extends Vue {
  @Prop({type: Object, default: null})
  public readonly keeper!: KeyKeeper | null;

  public message: ApiMessage | null = null;

  public async doDelete(): Promise<void> {
    try {
      const result = await ApiClient.deleteJson("Device/Delete?deviceId=" + this.keeper?.id);

      if (result.success)
        await this.$router.push("/products?msg=deleted");
      else
        this.message = fromResponse(result);
    } catch (e: unknown) {
      this.message = await handleApiError(e as Error);
    }
  }
}
