import type { BusyState } from "./helpers/BusyState";
import { defineStore } from "pinia";
import type { ComponentColor } from "@/components/ui/helpers";

export const useBusyStore = defineStore({
  id: "busy",

  state() {
    const busyState: BusyState = {
      active: false,
    }

    const viewport: BusyState = {
      active: false,
    }

    return {
      global: busyState,
      viewport: viewport,
    };
  },

  actions: {
    setGlobal(busy: boolean, message?: string, color: ComponentColor = "primary"): void {
      this.global.active = busy;
      this.global.message = message;
      this.global.color = color;
    },

    setViewport(busy: boolean, message?: string, color: ComponentColor = "primary"): void {
      this.viewport.active = busy;
      this.viewport.message = message;
      this.viewport.color = color;
    },
  },
});
