

























































import { fail } from "@/api/helper";
import type { ApiMessage, ApiResponse } from "@/api/models/common/ApiResponse";
import type { ComponentColor } from "@/components/ui/helpers";
import Vue from "vue";
import Component from "vue-class-component";
import type { CellDefinition } from "@/components/ui/SmbTable/SmbTable.vue";
import SmbTable from "@/components/ui/SmbTable/SmbTable.vue";
import { useBusyStore } from "@/store/busy";
import { useDevicesStore } from "@/store/devicesStore";
import { mapActions, mapState } from "pinia";
import type { Device } from "@/api/models/devices/Device";
import { DeviceState } from "@/api/models/devices/Device";
import type { RawLocation } from "vue-router";
import dayjs from "dayjs";
import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";

@Component({
  components: {
    SmbTable,
    SmbTextInput,
    SmbButton,
  },
  metaInfo: {
    title: "Geräteübersicht",
  },
  methods: {
    ...mapActions(useBusyStore, { setBusyViewport: "setViewport" }),
    ...mapActions(useDevicesStore, ["tryGetDevices"]),
  },
  computed: {
    ...mapState(useDevicesStore, ["devices"]),
  },
})
export default class Devices extends Vue {
  public readonly tryGetDevices!: (
    filter?: string | null,
    force?: boolean
  ) => Promise<ApiResponse>;
  public readonly setBusyViewport!: (
    busy: boolean,
    message?: string,
    color?: ComponentColor
  ) => void;
  public readonly viewMode!: string;
  public readonly devices!: Device[];

  public filter: string | null = null;

  public message: ApiMessage | null = null;
  public deviceCol = "";
  public deviceCheckNumber1 = -1;
  public deviceCheckNumber2 = 1;

  public async mounted(): Promise<void> {
    await this.getDevices();
  }

  public async getDevices(
    filter: string | null = null,
    force = false
  ): Promise<void> {
    try {
      this.setBusyViewport(true, "Lade Geräte …");
      this.message = await this.tryGetDevices(filter, force);
    } catch (e: unknown) {
      this.message = fail("Fehler beim Senden der Anfrage.");
    } finally {
      this.setBusyViewport(false);
    }
  }

  public deviceCellRenderer(device: Device, key: string): CellDefinition {
    if (key.toUpperCase() === "ACTIVEUNTIL") {
      if (
        device.product &&
        (device.product as string).toUpperCase() === "SMARTPHONE"
      ) {
        return {
          tag: "span",
          slot: dayjs(device[key] as string).format("YYYY MMMM DD"),
        };
      } else {
        return {
          tag: "span",
          slot: "Unbegrenzt",
        };
      }
    } else if (key.toUpperCase() === "STATE") {
      let stateText = "";

      switch (device[key]) {
        case DeviceState.Owned:
          stateText = "Im Besitz";
          break;
        case DeviceState.Lost:
          stateText = "Verloren gemeldet";
          break;
        case DeviceState.Found:
          stateText = "Fund gemeldet";
          break;
        case DeviceState.Progress:
          stateText = "In Bearbeitung";
          break;
        case DeviceState.Return:
          stateText = "In Rückführung";
          break;
        case DeviceState.Closed:
          stateText = "Abgeschlossen";
          break;
      }

      return {
        tag: "span",
        slot: stateText,
      };
    }

    return {
      tag: "span",
      slot: device[key],
    };
  }

  public deviceHeaderClicked(col: string): void {
    if (this.deviceCol === col) {
      this.deviceCheckNumber1 *= -1;
      this.deviceCheckNumber2 *= -1;
    } else {
      this.deviceCheckNumber1 = -1;
      this.deviceCheckNumber2 = 1;
    }

    this.deviceCol = col;

    this.devices.sort((a, b) => {
      if ((a[col] as string) < (b[col] as string))
        return this.deviceCheckNumber1;

      if ((a[col] as string) > (b[col] as string))
        return this.deviceCheckNumber2;

      return 0;
    });
  }

  public async deviceRowClicked(row: Device): Promise<void> {
    await this.$router.push(this.getLinkTo(row));
    return;
  }

  public getLinkTo(device: Device): RawLocation {
    return `device/${device.id}`;
  }

  public async callFilter(): Promise<void> {
    return this.getDevices(this.filter);
  }

  public async cancelFilter(): Promise<void> {
    this.filter = null;
    return this.getDevices(null, true);
  }
}
