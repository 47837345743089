import { render, staticRenderFns } from "./IdTagColorSelect.vue?vue&type=template&id=5b1d4fec&scoped=true&"
import script from "./IdTagColorSelect.vue?vue&type=script&lang=ts&"
export * from "./IdTagColorSelect.vue?vue&type=script&lang=ts&"
import style0 from "./IdTagColorSelect.vue?vue&type=style&index=0&id=5b1d4fec&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b1d4fec",
  null
  
)

export default component.exports