






import Vue from "vue";
import Component from "vue-class-component";

@Component({
  metaInfo: {
    title: "Rechteverwaltung"
  }
})
export default class AdminPermissions extends Vue {
}
