

































































































































































































































































































































































































































































import type { RegisterUserProfilePostForm } from "@/api/models/auth/RegisterUserProfilePostForm";
import type { ApiMessage } from "@/api/models/common/ApiResponse";
import SmbCheckbox from "@/components/ui/SmbCheckbox/SmbCheckbox.vue";
import SmbCodeInput from "@/components/ui/SmbCodeInput/SmbCodeInput.vue";
import SmbLoader from "@/components/ui/SmbLoader/SmbLoader.vue";
import SmbSwitch from "@/components/ui/SmbSwitch/SmbSwitch.vue";
import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import SmbAlert from "@/components/ui/SmbAlert/SmbAlert.vue";
import SmbSelect from "@/components/ui/SmbSelect/SmbSelect.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { mapActions, mapState } from "pinia";
import { useAuthStore } from "@/store/auth";
import { useProfileStore } from "@/store/profile";
import type { RegisterPostForm } from "@/api/models/auth/RegisterPostForm";
import type { ConfirmMailPostForm } from "@/api/models/auth/ConfirmMailPostForm";
import { Gender } from "@/api/models/auth/Gender";
import { fail, fromResponse } from "@/api/helper";
import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import { MailRegex, PasswordRegex } from "@/components/ui/helpers";
import PasswordChecklist from "@/components/helper/PasswordChecklist.vue";
import { Ref } from "vue-property-decorator";
import type { ReCaptchaInstance } from "recaptcha-v3";
import { load as loadReCaptcha } from "recaptcha-v3"
import type {NavigationGuardNext, Route} from "vue-router";

// eslint-disable-next-line
const CountryMap = require("@/assets/countries.json");

const RegisterProps = Vue.extend({
  data() {
    return {
      showPasswordHint: false,
      password1focus: false,
      password2focus: false,
    };
  },
  computed: {
    ...mapState(useAuthStore, [
      "isAuthenticated",
      "authenticatedUser",
      "mailConfirmed",
      "profileCompleted",
    ]),
  },
  watch: {
    password1focus(val: boolean) {
      setTimeout(() => this.showPasswordHint = val || this.password2focus, 100);
    },
    password2focus(val: boolean) {
      setTimeout(() => this.showPasswordHint = val || this.password1focus, 100);
    },
  },
  methods: {
    ...mapActions(useAuthStore, [
      "doCreateAccount",
      "doConfirmMail",
      "doResendCode",
      "doLogout",
      "tryAuthenticate",
    ]),
    ...mapActions(useProfileStore, ["tryCreateUserProfile"]),
  }
});

@Component({
  components: {
    PasswordChecklist,
    SmbApiAlert,
    SmbCheckbox,
    SmbSwitch,
    SmbCodeInput,
    SmbLoader,
    SmbTextInput,
    SmbButton,
    SmbAlert,
    SmbSelect,
  },
  metaInfo: {
    title: "Registrieren",
  },
})
export default class Register extends RegisterProps {
  @Ref()
  public readonly password1!: SmbTextInput;

  @Ref()
  public readonly password2!: SmbTextInput;

  public readonly Gender = Gender;
  public readonly Countries = CountryMap;
  public readonly mailRegex = MailRegex;
  public readonly passwordRegex = PasswordRegex;

  public step = 0;
  public busy = true;
  public recaptcha: ReCaptchaInstance | null = null;

  public step1Message: ApiMessage | null = null;
  public step1Form: RegisterPostForm = {
    mail: "",
    password: "",
    newsletter: false,
    recaptcha_token: "",
  };
  public passwordConfirmation = "";
  public agbAccepted = false;

  public step2Message: ApiMessage | null = null;
  public step2Code = "";
  public step2ResendCooldown = 0;

  public step3Message: ApiMessage | null = null;
  public step3Form: RegisterUserProfilePostForm = {
    city: "",
    company: "",
    postalCode: "",
    street: "",
    houseNumber: "",
    birthDay: "",
    birthMonth: "",
    birthYear: "",
    preName: "",
    gender: Gender.Empty,
    name: "",
    mobileNumber: "",
    phoneNumber: "",
    country: "DE",
  };

  public async mounted(): Promise<void> {
    if (this.isAuthenticated) {
      if (!this.mailConfirmed) {
        this.step = 1;
        this.step1Form.mail = this.authenticatedUser.mail;

        this.step2Message = fail(
          "Du musst deine Registrierung abschließen, bevor du dich anmelden kannst.",
        );
      } else if (!this.profileCompleted) {
        this.step = 2;
        this.step3Message = fail(
          "Du musst deine Registrierung abschließen, bevor du dich anmelden kannst.",
        );
      } else {
        // MIND: this could lead to an infinite redirect cycle if we are not careful
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        await this.$router.push("/login");
      }
    } else {
      this.recaptcha = await loadReCaptcha(
        process.env.VUE_APP_RECAPTCHA_KEY as string,
        {
          useRecaptchaNet: true,
          renderParameters: {
            hl: 'de',
          },
          explicitRenderParameters: {
            badge: 'bottomleft',
          },
        },
      );

      this.recaptcha.showBadge();
    }

    const cooldown = localStorage.getItem("register-mail-code-cooldown");
    if (cooldown !== null) {
      this.step2ResendCooldown = Number(cooldown) + 1;
      this.decreaseResendCooldown();
    }

    this.busy = false;
  }

  public beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    this.recaptcha?.hideBadge();

    next();
  }

  public async createAccount(): Promise<void> {
    this.step1Message = null;
    this.password1.visible = false;
    this.password2.visible = false;

    if (!(this.$refs.passwordChecklist as PasswordChecklist).allOk) {
      this.step1Message = fail(
        "Das Passwort muss dem geforderten Format entsprechen.",
      );

      return;
    }

    if (this.step1Form.password !== this.passwordConfirmation) {
      this.step1Message = fail("Die Passwörter müssen übereinstimmen.");

      return;
    }

    if (!this.agbAccepted) {
      this.step1Message = fail(
        "Du musst den AGB zustimmen, um dich zu registrieren.",
      );

      return;
    }

    try {
      this.busy = true;

      const token = await this.recaptcha?.execute('submit');
      if (!token) {
        this.step1Message = fail("Leider ist ein interner Fehler aufgetreten. Bitte lade die Seite neu.");

        return;
      }

      this.step1Form.recaptcha_token = token;

      const response = await this.doCreateAccount(this.step1Form);

      if (!response.success) {
        this.step1Message = fromResponse(response);

        return;
      }

      this.step = 1;
      this.step2Message = fromResponse(response);
      this.recaptcha?.hideBadge();
    } catch (e: unknown) {
      this.step1Message = fail((e as Error).message);
    } finally {
      this.busy = false;
    }
  }

  public async confirmMail(): Promise<void> {
    this.step2Message = null;

    try {
      this.busy = true;
      const response = await this.doConfirmMail({
        code: this.step2Code,
      } as ConfirmMailPostForm);

      if (!response.success) {
        this.step2Message = fromResponse(response);

        return;
      }

      this.step = 2;
      this.step3Message = fromResponse(response);
    } catch (e: unknown) {
      this.step2Message = fail((e as Error).message);
    } finally {
      this.busy = false;
    }
  }

  public async finalizeAccount(): Promise<void> {
    this.step3Message = null;

    try {
      this.busy = true;

      const response = await this.tryCreateUserProfile(this.step3Form);
      if (!response.success) {
        this.step3Message = fromResponse(response);

        return;
      }

      // If the registration was successful, we need to update our local state of the user.
      await this.tryAuthenticate();

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      await this.$router.push("/");
    } catch (e: unknown) {
      this.step3Message = fail((e as Error).message);
    } finally {
      this.busy = false;
    }
  }

  public async abortStep1(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    await this.$router.push("/login");
  }

  public async abortStep2(): Promise<void> {
    await this.doLogout();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    await this.$router.push("/login");
  }

  public async abortStep3(): Promise<void> {
    await this.doLogout();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    await this.$router.push("/login");
  }

  public async resendCode(): Promise<void> {
    try {
      this.busy = true;
      const response = await this.doResendCode();

      this.step2Message = fromResponse(response);

      // Use 31 seconds and decrease directly because of the side effects decreaseResendCooldown() has
      // (e.g. storing the cooldown in localstorage)..
      this.step2ResendCooldown = 31; // s
      this.decreaseResendCooldown();
    } catch (e: unknown) {
      this.step2Message = fail((e as Error).message);
    } finally {
      this.busy = false;
    }
  }

  public decreaseResendCooldown(): void {
    this.step2ResendCooldown--;

    if (this.step2ResendCooldown > 0) {
      localStorage.setItem("register-mail-code-cooldown", String(this.step2ResendCooldown));

      setTimeout(() => { this.decreaseResendCooldown(); }, 1000);
    } else {
      localStorage.removeItem("register-mail-code-cooldown");
    }
  }
}
