

















import Vue from "vue";
import Component from "vue-class-component";
import SmbSwitch from "@/components/ui/SmbSwitch/SmbSwitch.vue";
import {mapWritableState} from "pinia";
import type { MessageSettings } from "@/store/settings";
import { useSettingsStore } from "@/store/settings";

@Component({
  components: { SmbSwitch },
  metaInfo: {
    title: "Einstellungen",
  },
  computed: {
    ...mapWritableState(useSettingsStore, ["messages"]),
  },
})
export default class Settings extends Vue {
  public readonly messages!: MessageSettings;
}
