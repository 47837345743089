












import Vue from "vue";
import Component from "vue-class-component";
import type { IdTag } from "@/api/models/products/IdTag";
import { Prop } from "vue-property-decorator";
import type { ComponentColor } from "@/components/ui/helpers";

@Component
export default class IdTagPairedLabel extends Vue {
  @Prop({ type: Object, required: true })
  public readonly idTag!: IdTag;

  public get hasPairedState(): boolean {
    return this.idTag.isSmartphone;
  }

  public get pairedIcon(): string | undefined {
    if (!this.hasPairedState)
      return undefined;

    return this.idTag.fcmToken !== null ? "link" : "unlink";
  }

  public get pairedIconColor(): ComponentColor | undefined {
    if (!this.hasPairedState)
      return undefined;

    return this.idTag.fcmToken !== null ? "success" : "default";
  }
}
