













































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";

import {useBusyStore} from "@/store/busy";
import {mapActions, mapWritableState} from "pinia";

import {fail, fromResponse} from "@/api/helper";

import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import SmbSelect from "@/components/ui/SmbSelect/SmbSelect.vue";
import {useProfileStore} from "@/store/profile";
import type {ApiMessage, ApiResponse} from "@/api/models/common/ApiResponse";
import {Gender} from "@/api/models/auth/Gender";
import {Ref} from "vue-property-decorator";

@Component({
  components: { SmbApiAlert, SmbButton, SmbTextInput, SmbSelect },
  metaInfo: {
    title: "Profil anpassen",
  },
  computed: {
    ...mapWritableState(useProfileStore, [
      "gender",
      "preName",
      "name",
      "birthDay",
      "birthMonth",
      "birthYear",
      "phoneNumber",
      "mobileNumber",
      "street",
      "houseNumber",
      "postalCode",
      "city",
      "profileImageSrc",
    ]),
  },
  methods: {
    ...mapActions(useBusyStore, ["setViewport"]),
    ...mapActions(useProfileStore, [
      "tryGetUserProfile",
      "tryUpdateUserProfile",
      "tryUpdateUserProfileImage",
      "resetProfile",
    ]),
  },
})
export default class Profile extends Vue {
  @Ref()
  public readonly fileInput!: HTMLInputElement;
  @Ref()
  public readonly genderSelect!: SmbSelect;
  @Ref()
  public readonly preNameInput!: SmbTextInput;
  @Ref()
  public readonly nameInput!: SmbTextInput;
  @Ref()
  public readonly birthDayInput!: SmbTextInput;
  @Ref()
  public readonly birthMonthInput!: SmbTextInput;
  @Ref()
  public readonly birthYearInput!: SmbTextInput;
  @Ref()
  public readonly postalCodeInput!: SmbTextInput;
  @Ref()
  public readonly cityInput!: SmbTextInput;
  @Ref()
  public readonly streetInput!: SmbTextInput;
  @Ref()
  public readonly houseNumberInput!: SmbTextInput;
  @Ref()
  public readonly phoneNumberInput!: SmbTextInput;
  @Ref()
  public readonly mobileNumberInput!: SmbTextInput;

  public gender!: Gender;
  public preName!: string;

  public name!: string;
  public birthDay!: string | number;
  public birthMonth!: string | number;
  public birthYear!: string | number;
  public phoneNumber!: string;
  public mobileNumber!: string;
  public street!: string;
  public houseNumber!: string;
  public postalCode!: string;
  public city!: string;
  public profileImageSrc!: string;

  public readonly Gender = Gender;
  public updateProfileMessage: ApiMessage | null = null;
  public isReadonly = true;

  public readonly setViewport!: (busy: boolean, message?: string) => void;
  public readonly tryUpdateUserProfile!: () => Promise<ApiResponse>;
  public readonly tryUpdateUserProfileImage!: () => Promise<ApiResponse>;
  public readonly tryGetUserProfile!: (force?: boolean) => Promise<ApiResponse>;
  public readonly resetProfile!: () => void;

  public async beforeMount(): Promise<void> {
    await this.tryGetUserProfile();
  }

  public async updateProfile(): Promise<void> {
    try {
      this.setViewport(true, "Profil wird geändert …");
      let response = await this.tryUpdateUserProfile();
      if (!response.success) {
        this.updateProfileMessage = fromResponse(response);

        return;
      }

      if (this.profileImageSrc) {
        response = await this.tryUpdateUserProfileImage();
        if (!response.success) {
          this.updateProfileMessage = fromResponse(response);

          return;
        }
      }

      this.updateProfileMessage = fromResponse(response);

      this.removeAnnotations();

      await this.tryGetUserProfile(true);
      this.isReadonly = true;
    } catch (e: unknown) {
      this.updateProfileMessage = fail("Fehler beim Senden der Anfrage.");
    } finally {
      this.setViewport(false);
    }
  }

  public onFileSelected(event: Event): void {
    let fileList: FileList = (event.target as HTMLInputElement).files as FileList;

    const reader = new FileReader();
    reader.readAsDataURL(fileList[0]);
    reader.onload = (): void => {
      this.profileImageSrc = reader.result as string;
    };
    reader.onerror = (error): void => {
      console.log("Error: ", error);
    };
  }

  public cancelEdit(): void {
    this.fileInput.value = "";
    this.isReadonly = !this.isReadonly;
    this.resetProfile();
    this.removeAnnotations();
  }

  public removeAnnotations(): void {
    this.genderSelect.resetError();
    this.preNameInput.resetError();
    this.nameInput.resetError();
    this.birthDayInput.resetError();
    this.birthMonthInput.resetError();
    this.birthYearInput.resetError();
    this.postalCodeInput.resetError();
    this.cityInput.resetError();
    this.streetInput.resetError();
    this.houseNumberInput.resetError();
    this.phoneNumberInput.resetError();
    this.mobileNumberInput.resetError();
  }
}
