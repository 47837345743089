















































import type {ApiMessage} from "@/api/models/common/ApiResponse";
import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import {useProductsStore} from "@/store/products";
import {mapActions, mapState, mapWritableState} from "pinia";
import Vue from "vue";
import Component from "vue-class-component";
import {useBusyStore} from "@/store/busy";
import type {ComponentColor} from "@/components/ui/helpers";
import {handleApiError, success} from "@/api/helper";
import {useLayoutStore} from "@/store/layout";
import type {IdTag} from "@/api/models/products/IdTag";
import type {KeyKeeper} from "@/api/models/products/KeyKeeper";
import KeyKeeperTable from "@/components/helper/KeyKeeperTable.vue";
import SmbToggleButton from "@/components/ui/SmbToggleButton/SmbToggleButton.vue";
import IdTagTable from "@/components/helper/IdTagTable.vue";
import IdTagCards from "@/components/helper/IdTagCards.vue";
import KeyKeeperCards from "@/components/helper/KeyKeeperCards.vue";

@Component({
  components: {
    IdTagCards,
    IdTagTable,
    SmbToggleButton,
    SmbApiAlert,
    KeyKeeperTable,
    KeyKeeperCards
  },
  metaInfo: {
    title: "Geräteübersicht",
  },
  methods: {
    ...mapActions(useProductsStore, { getAll: "getAll" }),
    ...mapActions(useBusyStore, { setBusyViewport: "setViewport" }),
  },
  computed: {
    ...mapState(useProductsStore, ["idTags", "keyKeepers"]),
    ...mapWritableState(useLayoutStore, { viewMode: "devicesView" }),
  },
})
export default class Products extends Vue {
  public readonly idTags!: IdTag[];
  public readonly keyKeepers!: KeyKeeper[];
  public readonly getAll!: (force?: boolean) => Promise<void>;
  public readonly setBusyViewport!: (busy: boolean, message?: string, color?: ComponentColor) => void;
  public readonly viewMode!: string;

  public message: ApiMessage | null = null;

  public async mounted(): Promise<void> {
    this.setBusyViewport(true, "Lade Produkte...");

    try {
      await this.getAll(true);
    } catch (e: unknown) {
      this.message = await handleApiError(e as Error);
    }

    if (typeof this.$route.query?.msg !== "undefined") {
      switch (this.$route.query?.msg) {
        case "added_id_tag":
          this.message = success("ID-TAG erfolgreich hinzugefügt!");
          break;
        case "added_key_keeper":
          this.message = success("KEYKEEPER erfolgreich hinzugefügt!");
          break;
        case "deleted":
          this.message = success("Erfolgreich gelöscht.");
          break;
        case "report_lost":
          this.message = success("Verlust erfolgreich gemeldet.");
          break;
        case "report_found_own":
          this.message = success("Verluststatus erfolgreich zurückgesetzt.");
          break;
      }
    }

    this.setBusyViewport(false);
  }
}
