import {nextTick} from "@vue/composition-api";

export class TokenBucketRateLimiter {
  private static enableLog = process.env.VUE_APP_DEBUG_LOG === "true";
  public maxTokens = 10;
  public tokenResetTimeoutMS = 1000;
  private tokensSpent = 0;
  private resetTimeoutHandle: number | null = null;

  public constructor() {
    this.reset();
  }

  private static log(...data: unknown[]): void {
    if (!TokenBucketRateLimiter.enableLog)
      return;

    console.debug("[TokenBucket]", ...data);
  }

  public async acquireToken<T>(fn: () => T): Promise<T> {
    this.scheduleReset();

    if (this.tokensSpent >= this.maxTokens) {
      TokenBucketRateLimiter.log("Bucket empty. Waiting to refill.");

      await new Promise(r => setTimeout(r, this.tokenResetTimeoutMS));

      return await this.acquireToken(fn);
    }

    this.tokensSpent++;
    TokenBucketRateLimiter.log("Tokens left:", this.maxTokens - this.tokensSpent);

    await nextTick();

    return fn();
  }

  private reset(): void {
    this.tokensSpent = 0;
    this.resetTimeoutHandle = null;

    TokenBucketRateLimiter.log("Tokens refilled.");
  }

  private scheduleReset(): void {
    // Only the first token in the set triggers the resetTimeout
    if (this.resetTimeoutHandle)
      return;

    this.resetTimeoutHandle = setTimeout(() => {
      this.reset();
    }, this.tokenResetTimeoutMS);
  }
}
