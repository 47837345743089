




















































































































































































































































































import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import { Gender } from "@/api/models/auth/Gender";
import { FinderReward } from "@/api/models/found/FinderReward";
import { MailRegex } from "@/components/ui/helpers";
import SmbCheckbox from "@/components/ui/SmbCheckbox/SmbCheckbox.vue";
import SmbSelect from "@/components/ui/SmbSelect/SmbSelect.vue";
import SmbTextInput from "@/components/ui/SmbTextInput/SmbTextInput.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import type { ApiMessage, ApiResponse } from "@/api/models/common/ApiResponse";
import { fail, fromResponse, handleApiError } from "@/api/helper";
import { useBusyStore } from "@/store/busy";
import { mapActions } from "pinia";
import ApiClient from "@/api/client/ApiClient";
import type { ReportFoundPostForm } from "@/api/models/found/ReportFoundPostForm";
import { ReturnOption } from "@/api/models/found/ReturnOption";
import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import type { ReCaptchaInstance } from "recaptcha-v3";
import { load as loadReCaptcha } from "recaptcha-v3";
import type {NavigationGuardNext, Route} from "vue-router";

@Component({
  components: {
    SmbApiAlert,
    SmbButton,
    SmbTextInput,
    SmbCheckbox,
    SmbSelect,
  },
  metaInfo: {
    title: "Fund melden",
  },
  methods: {
    ...mapActions(useBusyStore, ["setViewport"]),
  },
})
export default class ReportFind extends Vue {
  public readonly mailRegex = MailRegex;
  public securityNumber: null | string = null;
  public foundLocation: null | string = null;
  public finderReward: null | FinderReward = null;
  public gender = Gender.Empty;
  public preName = "";
  public name = "";
  public postalCode = "";
  public city = "";
  public street = "";
  public houseNumber = "";
  public phoneNumber = "";
  public email = "";
  public paypalEmail = "";
  public nameofBank = "";
  public nameOfTransferRecipient = "";
  public iban = "";
  public bic = "";

  public processing = false;

  public readonly Gender = Gender;
  public readonly FinderReward = FinderReward;
  public reportFoundMessage: ApiMessage | null = null;
  public readonly setViewport!: (busy: boolean, message?: string) => void;

  public postselected = false;
  public packageselected = true;
  public recaptcha: ReCaptchaInstance | null = null;

  @Watch("postselected")
  public onPostselectedChange(val: boolean): void {
    this.packageselected = !val;
  }

  @Watch("packageselected")
  public onPackageselectedChange(val: string): void {
    this.postselected = !val;
  }

  public async mounted(): Promise<void> {
    this.recaptcha = await loadReCaptcha(
      process.env.VUE_APP_RECAPTCHA_KEY as string,
      {
        useRecaptchaNet: true,
        renderParameters: {
          hl: "de",
        },
        explicitRenderParameters: {
          badge: "bottomright",
        },
      }
    );

    this.recaptcha.showBadge();
  }

  public beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    this.recaptcha?.hideBadge();

    next();
  }

  public async reportFound(): Promise<void> {
    const token = await this.recaptcha?.execute("submit");
    if (!token) {
      this.reportFoundMessage = fail(
        "Leider ist ein interner Fehler aufgetreten. Bitte lade die Seite neu."
      );

      return;
    }

    try {
      this.processing = true;
      this.setViewport(true, "Fund wird gemeldet …");
      this.reportFoundMessage = await this.tryReportFound(token);
    } catch (e: unknown) {
      this.reportFoundMessage = fail("Fehler beim Senden der Anfrage.");
    } finally {
      this.processing = false;
      this.setViewport(false);
    }
  }

  public onFinderRewardChange(val: string): void {
    switch (val) {
      case FinderReward.Paypal:
        this.nameofBank = "";
        this.nameOfTransferRecipient = "";
        this.iban = "";
        this.bic = "";
        break;
      case FinderReward.Transfer:
        this.paypalEmail = "";
        break;
      default:
        this.paypalEmail = "";
        this.nameofBank = "";
        this.nameOfTransferRecipient = "";
        this.iban = "";
        this.bic = "";
        break;
    }
  }

  public async tryReportFound(recaptchaToken: string): Promise<ApiResponse> {

    try {
      const updateUserProfileResponse = await ApiClient.postJson(
        "device/ReportFound",
        {
          securityNumber: this.securityNumber,
          foundLocation: this.foundLocation,
          finderReward: this.finderReward,
          returnOption: this.postselected
            ? ReturnOption.Post
            : ReturnOption.ReturnPackage,
          gender: this.gender,
          preName: this.preName,
          name: this.name,
          postalCode: this.postalCode,
          city: this.city,
          street: this.street,
          houseNumber: this.houseNumber,
          phoneNumber: this.phoneNumber,
          email: this.email,
          paypalEmail: this.paypalEmail,
          nameofBank: this.nameofBank,
          nameOfTransferRecipient: this.nameOfTransferRecipient,
          iban: this.iban,
          bic: this.bic,
          recaptchaToken: recaptchaToken,
        } as ReportFoundPostForm
      );

      return fromResponse(updateUserProfileResponse);
    } catch (e: unknown) {
      return await handleApiError(e as Error);
    }
  }
}
