var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:"key-keeper-table",staticClass:"h-100"},[_c('smb-button',{staticClass:"px-5 mb-3 d-inline-block w-auto",attrs:{"color":"success","to":"/products/key-keeper/add"}},[_c('fa-icon',{attrs:{"icon":"plus"}}),_vm._v(" KEYKEEPER registrieren ")],1),_c('smb-table',{staticClass:"text-small",staticStyle:{"cursor":"pointer"},attrs:{"data":_vm.keyKeepers,"headers":{
      'icon': {
        tag: 'product-icon',
        attrs: {
          icon: _vm.keyKeeperIconColHeaderIcon,
          color: 'white',
          size: '3x'
        }
      },
      'securityNumber': 'Sicherheitscode',
      'description': 'Bezeichnung'
    },"cell-renderer":_vm.keyKeeperCellRenderer,"empty-text":"Keine KEYKEEPER gefunden"},on:{"row-clicked":_vm.keyKeeperRowClicked,"header-clicked":_vm.keyKeeperHeaderClicked}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }