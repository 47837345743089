




















import Vue from "vue";
import Component from "vue-class-component";
import { Model, Prop } from "vue-property-decorator";

@Component
export default class SmbDropdown extends Vue {
  @Model("toggle", { type: Boolean, default: false })
  public readonly menuOpen!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly noRoundTop!: boolean;

  @Prop({ type: String, default: undefined })
  public readonly maxHeight!: string | undefined;

  @Prop({ type: String, default: undefined })
  public readonly minHeight!: string | undefined;

  @Prop({ type: String, default: undefined })
  public readonly maxWidth!: string | undefined;

  @Prop({ type: String, default: undefined })
  public readonly minWidth!: string | undefined;

  @Prop({ type: Boolean, default: false })
  public readonly fullWidth!: boolean;

  public clicked(): void {
    this.$emit('toggle', !this.menuOpen);
  }

  public blurred(): void {
    if (!this.menuOpen)
      return;

    setTimeout(() => this.$emit("toggle", false), 150);
  }

  public get menuStyle(): string {
    let style = "";

    if (typeof this.maxHeight !== "undefined") {
      style += `max-height: ${this.maxHeight}; overflow-x: auto;`;
    }

    if (typeof this.minHeight !== "undefined") {
      style += `min-height: ${this.minHeight};`;
    }

    if (typeof this.maxWidth !== "undefined") {
      style += `max-width: ${this.maxWidth}; overflow-y: auto;`;
    }

    if (typeof this.minWidth !== "undefined") {
      style += `min-width: ${this.minWidth};`;
    }

    if (this.fullWidth) {
      style += "left: 0; right: 0;";
    }

    return style;
  }
}
