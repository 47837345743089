



























































































































































































import type {User} from "@/api/models/auth/User";
import type {MenuEntry} from "@/api/models/menu/MenuEntry";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";
import SmbDropdown from "@/components/ui/SmbDropdown/SmbDropdown.vue";
import SmbDropdownItem from "@/components/ui/SmbDropdown/SmbDropdownItem.vue";
import {useAuthStore} from "@/store/auth";
import {useProfileStore} from "@/store/profile";
import {useBusyStore} from "@/store/busy";
import {useLayoutStore} from "@/store/layout";
import {mapActions, mapState} from "pinia";
import Vue from "vue";
import Component from "vue-class-component";
import defaultProfilePicture from "@/assets/default-account.png";
import type {ApiResponse} from "@/api/models/common/ApiResponse";

@Component({
  components: { SmbDropdownItem, SmbDropdown, SmbButton },
  computed: {
    ...mapState(useAuthStore, ["user", "isAuthenticated"]),
    ...mapState(useLayoutStore, {
      title: (store) => store.title,
      headMenuItems: (store) => store.menus.head,
      sideTopMenuItems: (store) => store.menus.side.top,
      sideBottomMenuItems: (store) => store.menus.side.bottom,
    }),
    ...mapState(useProfileStore, ["profileImageSrc"]),
  },

  methods: {
    ...mapActions(useProfileStore, ["tryGetUserProfile"]),
    ...mapActions(useAuthStore, ["doLogout"]),
    ...mapActions(useBusyStore, { setGlobalBusy: "setGlobal" }),
  },
})
export default class DashboardHead extends Vue {
  public readonly user!: User;
  public readonly isAuthenticated!: boolean;
  public readonly title!: string;
  public readonly headMenuItems!: MenuEntry[];
  public readonly sideTopMenuItems!: MenuEntry[];
  public readonly sideBottomMenuItems!: MenuEntry[];
  public readonly profileImageSrc!: string;

  public readonly setGlobalBusy!: (show: boolean, message?: string) => void;
  public readonly tryGetUserProfile!: (force?: boolean) => Promise<ApiResponse>;
  public readonly doLogout!: () => Promise<void>;

  public profileMenuOpen = false;
  public mainMenuOpen = false;

  public async mounted(): Promise<void> {
    if (!this.isAuthenticated) {
      return;
    }

    // get user profile for profile image in head
    await this.tryGetUserProfile();
  }

  public async logout(): Promise<void> {
    this.setGlobalBusy(true, "Sie werden ausgeloggt …");

    try {
      await this.doLogout();

      await this.$router.push("/login");

      Vue.$toast.success("Erfolgreich ausgeloggt. Komm bald wieder!");
    } catch (e: unknown) {
      console.error(e);
    } finally {
      this.setGlobalBusy(false);
    }
  }

  public get displayName(): string | undefined {
    return this.user?.mail;
  }

  public profileMenuToggle(): void {
    this.profileMenuOpen = !this.profileMenuOpen;
    this.mainMenuOpen = false;
  }

  public mainMenuToggle(): void {
    this.mainMenuOpen = !this.mainMenuOpen;
    this.profileMenuOpen = false;
  }

  public closeMobileMenu(): void {
    this.profileMenuOpen = false;
    this.mainMenuOpen = false;
  }

  public get sortedHeadMenuItems(): MenuEntry[] {
    return [...this.headMenuItems].sort((e) => e.order);
  }

  public get sortedSideTopMenuItems(): MenuEntry[] {
    return [...this.sideTopMenuItems].sort((e) => e.order);
  }

  public get sortedSideBottomMenuItems(): MenuEntry[] {
    return [...this.sideBottomMenuItems].sort((e) => e.order);
  }

  public get profileImage(): string {
    return this.profileImageSrc ? this.profileImageSrc : defaultProfilePicture;
  }
}
