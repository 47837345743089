









































import type { ComponentColor } from "@/components/ui/helpers";
import type { SmbInput } from "@/components/ui/SmbInput";
import Vue from "vue";
import Component from "vue-class-component";
import { ModelSync, Prop, Ref } from "vue-property-decorator";

@Component
export default class SmbTextArea extends Vue implements SmbInput {
  @Ref()
  public readonly input!: HTMLInputElement;

  @ModelSync("text", "change", { type: [String, Number], default: "" })
  public value!: string | number;

  @Prop({ type: Boolean, default: false })
  public readonly readonly!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly required!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly disabled!: boolean;

  @Prop({ type: Number, default: 10 })
  public readonly rows!: number;

  @Prop({
    type: Number,
    default: undefined,
    validator: (val: unknown) =>
      typeof val === "undefined" || typeof val === "number",
  })
  public tabindex!: number | undefined;

  public focussed = false;
  public visited = false;
  public triedSubmitting = false;
  public error: string | null = null;
  public errorColor: ComponentColor = "danger";
  public visible = false;

  public checkInvalid(): void {
    this.triedSubmitting = true;

    this.updateError();
  }

  public get isEmpty(): boolean {
    return this.value === null || String(this.value).length === 0;
  }

  public get isRequiredValid(): boolean {
    return !this.required || !this.isEmpty;
  }

  public updateError(): void {
    if (!this.isRequiredValid) {
      if (!this.triedSubmitting) {
        this.error = null;

        return;
      }

      this.error = "Dieses Feld ist erforderlich";
      this.errorColor = "warning";

      return;
    }

    this.error = null;
  }

  public get isValid(): boolean {
    return this.error === null;
  }

  public focus(): void {
    this.input.focus();
  }

  public onFocusin(e: Event): void {
    this.focussed = true;

    this.$emit("focusin", e);
  }

  public onFocusout(e: Event): void {
    this.visited = true;
    this.focussed = false;

    this.$emit("focusout", e);

    this.updateError();
  }

  public get actualTabIndex(): number | undefined {
    return this.disabled ? -1 : this.tabindex;
  }

  public get stateClass(): string | undefined {
    if ((this.visited || this.triedSubmitting) && !this.isValid) {
      return this.errorColor;
    }

    return undefined;
  }

  public resetError(): void {
    this.triedSubmitting = false;
    this.error = null;
    this.errorColor = "danger";
  }
}
