
































































import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";

export interface CellDefinition {
  tag: string;
  slot?: unknown;
  attrs?: {
    [key: string]: unknown;
  };
}

@Component
export default class SmbTable<
  T extends { [key: string]: unknown; id?: number | string }
> extends Vue {
  @Prop({ type: Array, default: [] })
  public readonly data!: T[];

  @Prop({
    type: Function,
    default: (val: T) => {
      if (typeof val.id !== "undefined") {
        return val.id;
      }

      throw new Error(
        "Unable to get id of data row. Please provide an id-accessor function to SmbTable."
      );
    },
  })
  public readonly idAccessor!: (item: T) => number | string;

  @Prop({
    type: Function,
    default: (val: T, key: string) => {
      return { tag: "span", attrs: { text: val[key] } };
    },
  })
  public readonly cellRenderer!: (val: T, key: string) => CellDefinition;

  @Prop({
    type: [Array, Object],
    required: true,
  })
  public readonly headers!:
    | string[]
    | { [key: string]: string | CellDefinition };

  @Prop({ type: String, default: undefined })
  public readonly emptyText!: string | undefined;

  public get headerKeys(): string[] {
    if (Array.isArray(this.headers)) {
      return this.headers;
    }
    else {
      return Object.keys(this.headers);
    }
  }

  public getHeaderValue(key: string | number): string | CellDefinition {
    if (Array.isArray(this.headers) && Number.isInteger(key)) {
      return this.headers[key as number];
    } else if (typeof this.headers === "object") {
      return (this.headers as { [key: string]: string | CellDefinition })[
        key as string
      ];
    }

    throw new Error("Unable to get value of header for key:" + key);
  }

  public headerContent(key: string | number): CellDefinition {
    const header = this.getHeaderValue(key);
    if (Object.prototype.hasOwnProperty.call(header, "tag"))
      return header as CellDefinition;
    else
      return {
        tag: "span",
        slot: header as string,
      };
  }

  public cellContent(row: T, key: string): CellDefinition {
    return this.cellRenderer(row, key);
  }
}
