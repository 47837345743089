

































import type { SmbInput } from "@/components/ui/SmbInput";
import Vue from "vue";
import Component from "vue-class-component";
import { ModelSync, Prop, Ref } from "vue-property-decorator";

@Component
export default class SmbSelect extends Vue implements SmbInput {
  @Ref()
  public readonly element!: HTMLSelectElement;

  @ModelSync('value', 'change', {
    type: String,
    default: null,
    validator: (val: unknown) => val === null || typeof val === "string",
  })
  public selected!: string | null;

  @Prop({ type: String, default: "" })
  public label!: string;

  @Prop({ type: Boolean, default: false })
  public required!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly!: boolean;

  @Prop({ type: Boolean, default: false })
  public disabled!: boolean;

  @Prop({
    type: String,
    default: undefined,
    validator: (val: unknown) => typeof val === "undefined" || typeof val === "string",
  })
  public autocomplete!: string | undefined;

  @Prop({
    type: String,
    default: null,
    validator: (val: unknown) => val === null || typeof val === "string"
  })
  public emptyValue!: string | null;

  @Prop({
    type: Number,
    default: undefined,
    validator: (val: unknown) => typeof val === "undefined" || typeof val === "number"
  })
  public tabindex!: number | undefined;

  public triedSubmitting = false;

  public get actualTabIndex(): number | undefined {
    return this.disabled ? -1 : this.tabindex;
  }

  public get isValid(): boolean {
    return this.required && this.selected !== null;
  }

  public focus(): void {
    this.element.focus();
  }

  public get stateClass(): string | undefined {
    if (this.triedSubmitting && !this.isValid) {
      return "warning";
    }

    return undefined;
  }

  public resetError(): void {
    this.triedSubmitting = false;
  }
}
