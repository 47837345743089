



































































































import Vue from "vue";
import Component from "vue-class-component";
import { mapActions, mapState, mapWritableState } from "pinia";
import { useSettingsStore } from "@/store/settings";
import SmbAlert from "@/components/ui/SmbAlert/SmbAlert.vue";
import { useBlogStore } from "@/store/blog";
import type { BlogEntry } from "@/api/models/blog/BlogEntry";
import type { BlogPostsResponse } from "@/api/models/blog/BlogResponse";
import SmbApiAlert from "@/components/ui/SmbAlert/SmbApiAlert.vue";
import type { StateChanger } from "vue-infinite-loading";
import InfiniteLoading from "vue-infinite-loading";
import { ErrorReporter } from "@/api/client/ErrorReporter";
import SmbButton from "@/components/ui/SmbButton/SmbButton.vue";

@Component({
  components: { SmbButton, SmbApiAlert, SmbAlert, InfiniteLoading },
  metaInfo: {
    title: "Aktuelles",
  },
  methods: {
    ...mapActions(useBlogStore, ["loadNextPage"]),
  },
  computed: {
    ...mapWritableState(useSettingsStore, ["messages"]),
    ...mapState(useBlogStore, ["posts"]),
  },
})
export default class Home extends Vue {
  public readonly posts!: BlogEntry[];
  public readonly messages!: {
    welcome: boolean;
    appInfo: boolean;
  };

  private ["loadNextPage"]: (limit?: number) => Promise<BlogPostsResponse>;

  public async loadNextPosts($state: StateChanger): Promise<void> {
    try {
      const response = await this.loadNextPage(4);

      if (response.meta.pagination.next)
        $state.loaded();
      else
        $state.complete();
    } catch (e: unknown) {
      // add small delay for ux
      await new Promise(r => setTimeout(r, 1000));

      ErrorReporter.report(e as Error);

      $state.error();
    }
  }
}
